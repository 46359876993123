.navbar-items {
    width: 60%;
}
.navbar{
    padding: 0px 0px !important;
}
nav ul.items-navbar {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 12px;
}

ul.items-navbar li a {
    font-size: 15px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

ul.items-navbar li {
    margin: 0 7px;
    padding: 10px;
    text-align: center;
}

.user_section_notification {
    width: 100%;
    text-align: end;
    margin-top: 25px;
    position: relative;
}

.flight-ico {
    transform: rotate(45deg);
}
.itw-logo {
    width: 175px;
    background: #fff;
    margin-right: 5px;
}

.NavbarHeader{
    margin: 0 5px;
}

.logo {
    text-align: center;
    padding-top: 0px;
    display: flex;
    flex-basis: 10%;
    width: 25%;
}

.headerBox {
    background-color: #fff!important;
    border-bottom: 3px solid #fc9e15;
}

ul.items-navbar li a span svg {
    font-size: 20px;
}

svg.MuiSvgIcon-root {
    font-size: 20px;
}

button.btn-drop {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

button.btn-notify {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

.top-header-dropdown {
 
}
.balance-nav{
    width: 100%;
    display: flex;
}
.balanced-view {
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 500;
    width: auto;
    color: #fc9e15;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #ccc;
}

.dropdown-profile {
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.notify-cnt {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
}

.notify_show {
    display: block;
}

.notify_hide {
    display: none;
}

.notify-items {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 7px 10px;
    cursor: pointer;
}

.notify-items:hover {
    background: beige;
}

.notifycontenthover {
    position: absolute;
    width: 300px;
    right: 0;
    z-index: 9;
}

.higlighttxt {
    font-size: 13px;
    padding: 0 5px;
    white-space: nowrap;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Blurtxt {
    font-size: 11px;
    padding: 0 5px;
}

.notify-viewmore {
    padding: 15px;
    text-align: center;
}

.viewmore {
    font-size: 15px;
    color: #1675b8;
}

.blc-list {
    background: #fff;
    padding: 5px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 30%);
    right: 0;
    top: 34px;
    border-radius: 8px;
}

.itemblc {
    display: flex;
    align-items: center;
    padding: 7px 10px;
}

.blnc-items-l {
    flex-basis: 50%;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-align: start;
}

.blnc-items-r {
    flex-basis: 50%;
    text-align: end;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.blnc-items-r.balance-price {
    color: red;
}

.balance_show {
    display: block;
}

.balance_hide {
    display: none;
}

.profile_view {
    display: flex;
}

.tltprofile {
    flex-basis: 100%;
}

button.profile {
    font-size: 12px;
    padding: 4px 10px;
    text-align: left;
    width: max-content;
    
}

.viewdropdown {
    width: 270px!important;
    right: 1!important;
    top: 7px!important;
    left: -28px!important;
    z-index: 99;
}

.profile-bg {
    background: #46143d;
    padding: 10px;
}

.tlt-img-ico {
    width: 70px;
    height: 70px;
    border: 6px solid #9fcc3a;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}

.tlt-img-ico img {
    width: 100%;
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
    height: 100%;
}

.tlt-name {
    font-size: 17px;
    margin-top: 5px;
    color: #fff;
    font-weight: 500;
    text-align: right;
}

.activedate {
    font-size: 13px;
    color: #fff;
    padding-bottom: 10px;
}

.btnadmin {
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.link-contact {
    display: flex;
    text-align: center;
}

.contactnumber {
    text-align: right;
    font-size: 13px;
}

.contactmail {
    text-align: right;
    font-size: 13px;
    text-transform: lowercase;
}

button.btnadminlink {
    font-size: 10px!important;
    padding: 4px 8px!important;
}

.link-contact {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 4px 10px;
    background: azure;
}
.profilenChangespassword a {
    display: block;
    text-align: left;
}
ul.setlogout {
    padding: 0;
}
button.btnprofileset {
    padding: 5px 20px;
    font-size: 14px;
    text-transform: capitalize;
    width: 100%;
}
button.btnprofileset span {
    justify-content: flex-start;
}
button.changebtn {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.changebtn span {
    justify-content: flex-start;
}
button.linklogout {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.linklogout span {
    justify-content: flex-start;
}
ul a li.linklogout {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    text-align: center!important;
    width: 86px;
    margin: 0 auto;
}

/***updated header css**/

.header {
    background-color: #46143d;
    width: 100%;
    margin: 0;
    padding: 1px 30px;
    box-shadow: inset 0px -1px 0px #f3f3f4;
}
.Main-content-header {
    z-index: 999;
    position: sticky;
    top: 0;
}
.headercol {
    padding: 0!important;
}
.navbar-light .navbar-brand{
    flex-basis: 35%;
}

.navbar-light .navbar-nav .nav-link {
    color: #000!important;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #1675b8!important;
    font-weight: 500;
}

a.navbaritems.nav-link {
    padding: 0 12px!important;
}

.dropdown-toggle::after {
    display: none!important;
}

.dropdownitem.nav-item.dropdown a {
    margin-left: 5px;
    padding: 0 5px!important;
}
.dropdownitem.nav-item.dropdown a li {
    font-size: 15px;
}

a.recharge{
    margin-right: 10px !important;
    padding: 5px !important;
    font-size: 14px;
    font-weight: 500;
}

/******updated header css******/
.rc-menu{
    z-index: 999;
}
button.rc-menu-button.upadtedmenuitems {
    outline: none;
    border: none;
    background: transparent;
    font-weight: 500;
    margin: 0 6px;
    font-size: 18px;
    display: flex;
    color: #000;
}

button.rc-menu-button.upadtedmenuitems svg.MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
    margin-top: 2px;
}

a.nav-link {
    font-weight: 400!important; 
    background: transparent;
    font-size: 13px;
}

.tlt-id{
    font-size: 13px;
    color: #fff;
    text-align: right;
}
.btnprofileset{
    color: #fff;
    text-align: right;
    margin-bottom: 0px;
    font-size: 11px;
    border: 1px solid;
    width: max-content;
    float: right;
    padding: 2px 5px;
    margin-top: 5px;
}

.badge-custom-flight .MuiBadge-badge {
    font-size: 10px;
    height: 15px;
    background: #1675b8;
}

.topheader-left{
    flex-basis: 40%;
    justify-content: flex-start;
}
.topheader-center{
    flex-basis: 20%;
    justify-content: center;
    text-align: center;
}
.topheader-right{
    flex-basis: 40%;
    justify-content: flex-end;
}
.header .socialicons{
    text-align: center;
    justify-content: center;
    align-items: center !important;
    margin-right: 20px;
}
.header .socialicons a {
    font-weight: 400!important;
    background: #9d8052;
    font-size: 13px;
    color:  #46143d!important;
    display: inline-flex;
    margin-right: 10px !important;
    height: 40px;
    width: 40px;
    padding: 11px !important;
    border-radius: 50%;
    margin-top: 0 !important;
   
}

.header .socialicons a svg{
    font-size: 18px;
    color:  #fff!important;   
}

.auth-btns{
    display: flex;
}

.auth-btns .login-btn{
    width: max-content;
    background: #9d8052 !important;
    border: 1px solid #46143d;
    color: #fff !important;
    font-weight: 400 !important;
    border-radius: 30px !important;
    padding: 6px 30px !important;
    font-size: 14px;
}

.auth-btns .signup-btn{
    width: max-content;
    background: #46143d !important;
    border: 1px solid #46143d;
    color: #fff !important;
    font-weight: 400 !important;
    border-radius: 30px !important;
    padding: 6px 20px !important;
    font-size: 14px;

}

.dailogbox-container .MuiDialog-paper{
    max-width: 900px;
    overflow: hidden;
  }
  .login-dialog .MuiDialog-paper{
    max-width: 750px;
    overflow: hidden;
  }
  
  .dailogbox {
    display: flex;
    max-width: 100%;
    height: 600px;
}

.login-dialog .modal-dialog{
    max-width: 800px;
}

.dailogbox-container .modal-dialog{
    max-width: 800px;
}
  .inputsect_login {
    width: 50%;
    padding: 22px;
    position: relative;
    text-align: center;
  }
  .inputsect_signup {
    width: 100%;
    padding: 22px;
    position: relative;
    text-align: center;
  }
  .slidersect {
    width: 50%;
    height: 480px;
    background-color: #0977bc;
  }
    
    .titlefrm {
      font-size: 20px;
  }
  .btnpostion {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 15px;
  }
  .Btnlogin {
    width: 100%;
  }
  .loginfrg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  .loginfrg span {
    font-size: 14px;
    color: #000;
    font-weight: 400;
  }
  .textBoxlogin {
    margin-top: 6%;
  }
  .textBoxsign{
    margin-top: 5%;
  }
  img.logoimg {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  .signup-modal img.logoimg {
    width: 100%;
    height: 650px;
    object-fit: cover;
  }
  .namewithtilebox {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }
  .titleuser {
    flex-basis: 18%;
  }
  .titlefirstname {
    flex-basis: 38%;
  }
  .titlelastname {
    flex-basis: 38%;
  }
  .titlecode {
    flex-basis: 20%;
  }
  .titlenumber {
    flex-basis: 77%;
  }
  .ttext {
    font-size: 13px;
    margin-top: 10px;
    color: #808080;
  }
  .btnpostion_signup {
    width: 100%;
    margin-top: 10px;
  }
  .img_with {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .logingoogle {
    padding: 10px 0;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .logingoogle iframe{
    border: none;
   margin: auto !important;
  }

   iframe #container{
    padding: 0px !important;
    width: 95% !important;
    margin-left: 16px;
  }
  
  .titleuser .MuiFormControl-root, .titlecode .MuiFormControl-root {
    width: 100%;
    margin-top: 8px
  }
  
  .titleuser .MuiSelect-select , .titlecode .MuiSelect-select{
    padding: 9px 8px !important;
  }
  
  .inputsect_signup label, .inputsect_login label{
    display: block;
    text-align: left;
    line-height: 10px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
  }
.texttitle{
    position: relative;
    bottom: 0px !important;
    font-size: 12px;
    padding-bottom: 0px;
  }

  .loginlogo {
    width: 70px;
}

.emailfield input{
    padding: 10px;
    font-size: 14px;
}

.forgot-password span{
    font-size: 15px;
    color: #f44336 !important;
    margin-bottom: 5px !important;
    display: block;
    cursor: pointer;
}

.signup-text{
    margin-top: 10px;
}

.signup-text a{
    font-size: 13px;
}
.signup-text button{
    background: #fff;
    color: #46143d;
    font-size: 13px;
}
.captchaSection {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
.captchaImg {
    height: 20px;
    width: 20px;
    margin : 5px;
}

.popupHeader h6 {
    font-size: 17px;
    font-weight: 600;
}

h3.subtlt-popup {
    font-size: 15px;
    font-weight: 500;
    padding: 0 9px;
    margin: 15px 0 0;
}
.inputforgot input {
    width: 100%;
    padding: 12px;
    font-size: 13px;
}
.inputforgot {
    width: 95%;
    margin: 8px 0 8px 10px!important;
}

.smalltxt {
    padding: 8px 12px;
    font-size: 14px!important;
    color: #a5a5a5;
}

.header.sticky-parent{
    overflow: visible;
}

.header.sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 0px !important;
    z-index: 999;
}
.box-header-clickaway{
    width: 100%;
}

.logo-side{
    display: inline-block;
    position: absolute;
    top: 16px;
}

.logo-side p{
    margin-bottom: -2px;
    font-size: 8px;
    color: #fff;
    margin-left: 15px;
}

.logo-side img{
    width: 100px;
    border-left: 1px solid #ffffff82;
    padding-left: 14px;
    margin-top: 4px;
}

@media (max-width: 786px) {
    .header .socialicons{
        justify-content:flex-start !important;
    }
    .itw-logo {
        width:120px !important;
    }
    .logo-side img {
        width: 80px;
    }
    .logo-side p {
        margin-bottom: -6px;
    }
    .navbar-light .navbar-brand {
        flex-basis: 50%;
    }
    .navbar-toggler-icon {
        width: 20px !important;
        height: 20px !important;
    }

    .Main-content-header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .navbar-collapse.collapse.show{
        position: absolute;
        background: #fff;
        width: 100%;
        top: 75px;
        right: 0px;
        box-shadow: 2px 4px 7px 2px #ccc;
        padding: 20px;
        text-align: center;
        z-index: 999;
    }

    .navbar-collapse.show{
        display: flex !important;
        align-items: flex-start !important;
        flex-flow: wrap-reverse;
    }
    .navbar-menu{
        margin-right: 15%;
        margin-left: 0px !important;
        text-align: left;
        align-items: baseline;
    }
    .tlt-name {
        font-size: 12px;
    }
    .top-header-dropdown {
        text-align: center;
        z-index: 9;
    }
    .itemblc {
        display: flex;
        align-items: center;
        padding: 4px 0px;
    }
    .navbar-expand{
        justify-content: flex-start !important;
        padding: 20px !important;
    }
    .navbar-expand .navbar-nav {
        flex-direction: column !important;
    }

    .header-topup {
        width: max-content;
    }
    .box-header-clickaway{
        width: 30%;
        text-align: right;
    }
    .box-header-clickaway button{
        background: #c7a367;
        padding: 3px !important;
        border-radius: 2px !important;
    }
    .navbar {
        padding: 5px 0px !important;
    }
}