
.footeraddress {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    flex-basis: 27%;
    height: auto !important;
}
.footer {
    background: #000000de;
    color: #fff;
    padding: 20px 30px 20px;

}
.footerimg img {
    width: 60%;
    /* background: #fff; */
}
.footeraddress p, .footeraddress p a {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
    color: #fff !important;
}
.copyright {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    width: 100%;
}
.socialicons {
    width: 100%;
    display: flex;
    border: none !important;
}
.linklist p {
    font-size: 14px;
    padding-right: 35px;
    text-align: justify;
}
.linklistul {
    flex-basis: 25%;
    height: auto !important;
}
.linklistul ul {
    list-style-type: none;
    font-size: 13px;
    margin-bottom: 0;
}
.linklistul ul li {
    line-height: 2;
}
.linklistul ul li a{
    color: #fff !important;
}
.socialicons a svg {
    font-size: 22px;
    color: #000;
}
.footerimg {
    display: block;
}
.footerlogowrp{
    /* margin: auto; */
}
.linklist ul li {
    line-height: 2;
}

.flexboxlink {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 3px solid #fff;
}
.listlinkitemsbox {
    display: flex;
    max-width: 100% !important;
}
.linklist {
    margin-top: 35px;
}

.footer .container-fluid{
    max-width: 1220px;
}
.whatsappchat:hover{
    color: #fff !important;
}
.whatsappchat svg{
    color: #fff !important;
    font-size: 20px !important;
}
.footerPhoneNo svg{ 
    color: #fff;
    font-size: 20px !important;
}
.footer a.nav-link , .whatsappchat{
    font-weight: 400!important;
    background: transparent;
    font-size: 13px;
    color: #fff !important;
}



.socialicons a{
    font-weight: 400!important;
    background: #fff;
    font-size: 13px;
    color: #fff !important;
    display: inline-flex;
    margin-right: 10px !important;
    padding: 10px !important;
    border-radius: 50%;
    margin-top: 15px !important;
}