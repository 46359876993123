.filter-content-ec {
    width: 100%;
    background: rgba(70,20,61,.03137254901960784);
    padding: 15px;
    border-radius: 8px;
    margin-top: 0;
    box-shadow: 0 2px 3px 0 #ccc;
}
.filter-ec{
    padding-right: 30px;
}
.boxdivition {
    padding: 15px;
    margin: 15px 0;
    background: #fff;
    border-radius: 8px;
    border: none;
}

.tltrange-filter {
    flex-basis: 50%;
    font-size: 16px;
    font-weight: 600;
}

.tltrangereset {
    font-size: 14px;
    font-weight: 500;
    color: tomato;
    width: 55%;
    cursor: pointer;
    text-align: right;
}

.MuiFormControl-root{
    width: 100%;
}

.depositdate-picker input{
    font-size: 13px;
    padding: 8px;
    width: 100%;
}

.report-tlt{
    font-size: 14px;
}

.fc-button-primary{
    background: #46143e !important;
}

.fc-event-main span{
    font-size: 11px;
    padding: 1px 4px !important;
    margin-bottom: 2px;
    cursor: pointer;
}

.fc-daygrid-event-harness{
    margin-top: 2px !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em !important;
}

.label-detector{
    position: fixed;
    top: 25%;
    right: 0;
    z-index: 99;
}
.label-detector ul{
    list-style: none;
    padding: 0px;
}

.label-detector ul li {
    list-style: none;
    padding: 3px 8px;
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 500;
    /* width: max-content;
    margin-right: 15px; */
    border-radius: 2px;
}

.label-detector ul li span{
    height: 5px;
    width: 15px;
    display: inline-block;
    margin-right: 3px;
}
/* cricket label */
.label-cricket {
    background: #c4513f3d;
    /* color: #c4513f; */
}
.label-cricket span{
    border: 4px solid #C4513F;
}

/* Golf label */
.label-golf {
    background: #4F86A43d;
    /* color: #4F86A4; */
}
.label-golf span{
    border: 4px solid #4F86A4;
}

/* football label */
.label-football {
    background: #1F85393d;
    /* color: #1F8539; */
}
.label-football span{
    border: 4px solid #1F8539;
}

/* tennis label */
.label-tennis {
    background: #485C2C3d;
    /* color: #485C2C; */
}
.label-tennis span{
    border: 4px solid #485C2C;
}

/* formula label */
.label-formula {
    background: #837D783d;
    /* color: #837D78; */
}
.label-formula span{
    border: 4px solid #837D78;
}

/* music label */
.label-music {
    background: #02AFD43d;
    /* color: #02AFD4; */
}
.label-music span{
    border: 4px solid #02AFD4;
}

.filter-content-ec {
    display: block;
}
.filter-content-res, .filter-btn-xs{
    display: none !important;
}
@media (max-width: 786px) {
.filter-content-ec {
    display: none;
}
.filter-content-res, .filter-btn-xs {
    display: block !important;
}
.label-detector {
    position: sticky;
    top: 93px;
    right: 0;
    z-index: 99;
    padding-top: 4px;
    background: #fff;
}   
.label-detector ul {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}
.label-detector ul li{
    margin-right: 10px;
}
button.filter-btn-xs, button.filter-btn-xs:hover, button.filter-btn-xs:active{
    font-size: 13px;
    padding: 3px 10px;
    background-color: #c7a467 !important;
    border: 1px solid;
    margin-top: -20px;
    margin-bottom: 7px;
    float: right;
}
.filter-ec{
    padding-right: 0px;
}
.fc .fc-button{
    padding: 2px 7px !important;
}
}

.fc-view-harness-active{
    height: 800px !important;
}