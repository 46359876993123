@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Concert+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #fff !important;
  overflow-x: hidden;
}
a {
  padding: 0!important;
  margin: 0!important;
  color: #000!important;
}
a:hover{
  text-decoration: none!important;
  color: #f6882d!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.calendarBox-airpassenger {
  padding: 15px;
  width: 92%;
  height: 60%;
  margin: 1% auto;
  border-radius: 10px;
}


.calendarBox {
  padding: 15px;
  width: 60%;
  height: 60%;
  margin: 1% auto;
  border-radius: 10px;
}
.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 103px;
  padding: 10px;
  font-weight: 500;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: aliceblue;
}
.daterate {
  width: 100%;
  font-size: 13px;
  color: #000;
}
.daterate label {
  margin: 0;
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #4caf50;
  cursor: pointer;
}
.daterate span {
  margin: 0;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 13px;
  color: #585454;
}
.fc-h-event {
  display: block;
  border: none;
  background-color: transparent;
}
table.fc-col-header tr {
  background: #46143d;
}
a.fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 500;
  color: #fff!important;
}
.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0;
  color: #46143d;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 8px;
}
.fc {
  width: 100%;
}
.fc .fc-button-primary {
  color: #fff;
  background: #46143d;
}
table.fc-col-header tr th {
  border: 1px solid #46143d;
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.tableico {
  margin-top: 5px;
  margin-bottom: 3px;
}

.tooltiprow {
  width: 100%;
}
.tooltipdtlsim{
  display: flex;
  align-items: flex-start;
}
.tooltipdtlsim label {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  margin-top: 7px;
  padding-left: 10px;

}
.tooltipdtls label{
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  margin: 0;
}
span.pricemini {
  font-size: 12px;
}
.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
  background: transparent;
}
.fc .fc-scrollgrid {
  border-collapse: separate;
  border-right-width: 0;
  border-bottom-width: 0;
}
.fc .fc-scroller-liquid-absolute {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.fc .fc-scrollgrid-section-body table, .fc .fc-scrollgrid-section-footer table {
  border-bottom-style: hidden;
  background: #fff;
}
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #f2f2f2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .flightsearchpagebg {
    background: #f2f2f2;
    width: 100%;
    height: 100%;
  }
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
body{
    font-family: 'Rubik', sans-serif !important;
}
.box-tlt {
    font-size: 15px;
    font-weight: 600;
    color: #46143d;
    text-transform: uppercase;
    margin-bottom: 5px!important;
}
ul, ul li{
    list-style: none;
    padding: 0;
}
.listopt {
    font-size: 15px;
}

.optitems .MuiOutlinedInput-root {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap !important;
}

.optitems .MuiInputBase-adornedStart{
    padding-right: 0px !important;
}
.optitems .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-popupIndicator{
    padding: 0px;
}

.optitems .MuiAutocomplete-endAdornment{
    position: relative !important;
    display: flex;
}
/* button.btn-class {
    font-size: 11px;
    font-weight: 500;
    color: #000;
    border: 1.2px solid #ccc;
    width: 100%;
} */
.flight-searschi-n-carousel {
    position: relative;
    min-height: calc(100vh - 90px - 200px);
    margin-bottom: 50px;
}
.btn-way {
    width: 110px;
    font-size: 13px!important;
    font-weight: 400!important;
    text-transform: capitalize!important;

}
.inputField {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 1px!important;
    margin-bottom: 5px!important;
    border-radius: 8px;
}
.inputField input {
    font-size: 13px!important;
}

.inputFieldLocation {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 3px!important;
    margin-bottom: 5px!important;
    border-radius: 8px;
}
 .inputFieldLocation input {
    font-size: 13px!important;
}
.inputFieldLocation input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputFieldLocation input:-ms-input-placeholder, textarea:-ms-input-placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputFieldLocation input::placeholder ,textarea::placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputField input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputField input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputField input::placeholder,textarea::placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputDate input::-webkit-input-placeholder {
    color: #000!important;
}
.inputDate input:-ms-input-placeholder {
    color: #000!important;
}
.inputDate input::placeholder {
    color: #000!important;
}

.lineBar {
    width: 100%;
    padding: 1px;
    margin: 15px 0;
    background-color: #ccc;
}

.btnGroup {
    width: 100%;
}
.inputDate {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 8px!important;
    margin-bottom: 5px!important;
}
.inputDate input {
    font-size: 13px!important;
}
.inputDate input::-webkit-input-placeholder {
    /* color: #1675b8!important; */
}
.inputDate input:-ms-input-placeholder {
    /* color: #1675b8!important; */
}
.inputDate input::placeholder {
    /* color: #1675b8!important; */
}
.addon-btn {
    font-size: 15px!important;
    font-weight: 400!important;
    width: 75%;
    margin-left: 25%!important;
}
.cntrowswp {
    position: relative;
}
.nonstopbtn {
    display: inline!important;
}
.nonstop-tlt {
    margin-left: 0px!important;
}

.nonstop-tlt span {
    font-size: 16px;
    color: #000;
    /* font-weight: 700; */
}
.nonstop-tlt .MuiCheckbox-root {
    padding: 9px;
}
.checkbox-tag {
    font-size: 15px;
    /* font-weight: 700; */
    color: #46143d;
}
.WidthSet {
    max-width: 1000px;
    width: 100%;
}
div#nav-tabpanel-2, div#nav-tabpanel-1, div#nav-tabpanel-0 {
    background: #fff;
}
svg.icoarrow {
    font-size: 40px!important;
}
.tlt-btn-more {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    cursor: pointer;
}
.tlt-mini-tag {
    margin: 0;
    font-size: 12px;
    color: #808080;
}
label.nonstop-tlt span {
    font-size: 12px!important;
    font-weight: 600;
}
.option-items {
    padding-left: 40px;
}

.ClassSelector {
    width: 100%;
    font-size: 17px!important;
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    padding: 6px 0px;
    font-family: 'Rubik', sans-serif !important;
}
.items-list {
   border-bottom: .5px solid #ccc!important;
   font-size: 14px!important;
   padding: 5px!important;
}
.ClassSelector>div {
    width: 100%;
    font-size: 11px!important;
    background: transparent;
    border: none;
    outline: none;
    font-weight: 500px;
    padding: 0px 25px !important;
    font-family: 'Rubik', sans-serif !important;
}
.divSet {
    position: relative;
}
.formRow {
    position: relative;
}
svg.times-icon {
    position: absolute;
    top: 36px;
    right: -26px;
    cursor: pointer;
}
.tabsPanelContainer {
    padding-right: 15px!important;
}
.tabsPanelbdr {
    box-shadow: 3px 2px 4px 1px #e9ecef;
    border-top: 2px solid #46143d;
}
.text-red {
    color: #dc3545!important;
}

.tabsPanelbdr .box-tlt{
    padding: 0 10px !important;
}
.prstColl {
    position: relative;
    padding-right: 20px!important;
}
.prstColr {
    position: relative;
    padding-left: 20px!important;
}
svg.locationiconl {
    position: absolute!important;
    top: 40px;
    left: 13px
}
svg.locationiconr {
    position: absolute;
    top: 40px;
    left: 25px;
}
.gpgutter {
    margin-top: -10px;
}

.swipebox_multicity {
    position: absolute;
    top: 30px;
    left: 32%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify {
    position: absolute;
    top: 30px;
    left: 49%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify_multicity {
    position: absolute;
    top: 30px;
    left: 32%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify_calenderfare{
    position: absolute;
    top: 30px;
    left: 49%;
    z-index: 999;
    cursor: pointer;
}


.BOOKING_CONFIRMED{
    padding: 3px 15px;
    background: #28a745;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}


.BOOKING_INCOMPLETE, .BOOKING_INCOMPLETE, .BOOKING_VOUCHERED , .BOOKING_PENDING, .BOOKING_FAILED, .BOOKING_INPROGRESS, .CANCELLATION_INPROGRESS  {
    padding: 3px 15px;
    background: #3c8dbc;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}


.BOOKING_CANCELLED{
    padding: 3px 15px;
    background: #dd4b39;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}

.BOOKING_HOLD{
    padding: 3px 15px;
    background: #00c0ef;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}


.search-wpr{
    display: flex;
    padding: 10px 15px !important;
    border-radius: 20px;
    background: #fef7f8;
}

.BOOKING_ERROR{
    padding: 3px 15px;
    background: #28a745;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
}
.p-font14{
    font-size: 12px;
}
.p-font14-rd{
    font-size: 12px;
}

.Slider-deales-xs {
    padding-top: 2%;
    padding-bottom: 2%;
}
/* MUI Button Override CSS */



.clear {
    color: rgba(0, 0, 0, 0.87) !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0 !important; 
}
.MuiButton-sizeSmall{
    text-transform: uppercase;
    min-width: 50px;
    padding: 4px 14px !important;
   
}

button.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.MuiButton-outlined{
    border-color:  #ccc !important;
}

.MuiButton-outlined:hover{
    border: 1px solid #ccc !important;
}

.MuiCardActionArea-root {
    display: block !important;
}

.btnGroup .MuiButtonBase-root{
    padding: 3px 9px !important;
    border-radius: 4px !important;
    border: 1px solid #ccc !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:last-of-type) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-color: transparent !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:last-child) {
    border-right-color: transparent !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:first-of-type) {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0 !important;
    margin-left: -1px !important;
}

.btnGroup .MuiListItem-root{
    display: flex !important;
}

.sliderrange{
    height: 2px !important;
}

.MuiSlider-thumb{
    width: 12px !important;
    height: 12px !important;
    border-radius: 2px !important;
}
.items-list:hover {
    background-color: #46143d!important;
    color: #fff;
}

.makeStyles-formControl-4 {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
}

fieldset{
    border: none !important;
    border-width: none !important;
    border-color: none !important;
}
.flighttabsdetails-link {
    padding: 0px 15px !important;
    font-size: 10px !important;
    height: 22px !important;
    min-height: 30px !important;
}
.MuiOutlinedInput-root {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.MuiOutlinedInput-root:hover {
    border: 1px solid #46143d !important;
}

 .MuiAutocomplete-input {
    padding: 2.5px 38px 2.5px 1px !important;
    padding-left: 5px !important;
}

.MuiAutocomplete-endAdornment {
    right: 9px;
    top: auto !important;
}

.MuiSelect-select{
    padding: 6.4px 8px !important;
}
/* 
.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 5px !important;
} */

.modifydata .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0px !important;
}

.timingroup .MuiButton-sizeSmall {
    padding: 4px 9px !important;
}

.Mui-checked {
    /* color: #fc9e15 !important; */
}
.MuiPagination-ul{
    justify-content: center;
}
.MuiPagination-ul .Mui-disabled{
    background-color: #f2f2f2 !important;
}
.MuiPagination-ul .MuiPaginationItem-root{
    border-radius: 50% !important;
    transition: none !important;
}
.MuiPaginationItem-page:hover{
    border-radius: 50% !important;
    transition: none !important;
}
.MuiPagination-ul .Mui-selected {
    border-radius: 50% !important;
    transition: none !important;
}
.CircularProgress{
    justify-content: center;
}
.agecontrol select{
    padding: 23px 12px 5px !important;
}
input[type="file"] {
    padding: 5px 10px 14px!important;
}
.PrivateSwitchBase-root{
	background-color: transparent !important;
}
.MuiButtonGroup-grouped {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}

table {
	display: table !important;
	visibility: visible !important;
}

.MuiMenu-list li{
    display: flex !important;
    padding: 6px 16px !important;
    font-size: 16px !important;
}

/* .MuiMenu-list li.Mui-selected{
    background-color: rgb(254 247 248) !important;
} */

.banner-bg-image{
    height: 550px;
}

.icon-time{
    color: #000;
}

.share-checkbox{
    padding: 0px 15px !important;
}


.gst_width{
    width: 750px !important;
}

.colspacemini {
    padding: 0!important;
}

.modifydata .MuiTabs-flexContainer {
    width: 50% !important;
    background: #fff;
}

.search-wpr .btnSend {
    font-size: 15px !important;
    border-radius: 24px !important;
    float: right;
    margin: 8px 8px 0px !important;
    font-weight: 700 !important;
    padding: 8px 50px !important;
    border-radius: 6px !important;
    text-transform: uppercase !important;
}

.addon-btn {
    margin-top: 10px !important;
    background-color: #fff !important;
    color: #46143d !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 20px !important;
}

.r-table-container{
    background: transparent !important;
    box-shadow: none !important;
    overflow-x: visible !important;
    overflow-x: initial !important;
}


.MuiFormHelperText-root.Mui-error, .MuiFormHelperText-root, .text-danger{
    margin: 0 !important;
    font-size: 11px !important;
    color: #dc3545!important;
}
/* Responsive CSS Ends */

.react-multiple-carousel__arrow {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 21px;
    height: 20px;
    padding: 0;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
    cursor: pointer;
    color: #46143d;
    z-index: 1 !important;
    border: none;
    outline: none;
    background: #46143d !important;
}

.MuiSnackbar-root {
    z-index: 99999 !important;
}

/* new UI for ITWgo */

.light {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
       bottom: 295px;
    position: absolute;
    z-index: 10;
    left: 20px;
    -webkit-animation: light 400ms ease-in-out 0s infinite alternate;
            animation: light 400ms ease-in-out 0s infinite alternate;
  }
  .cloud {
    position: absolute;
    top: 13px;
    right: -500px;
    opacity: .8;
    animation: cloud 30s linear 0s infinite reverse;
    z-index: 9;
  }

  .cloud img{
      width: 30px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
  }
  @-webkit-keyframes light {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @keyframes light {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @-webkit-keyframes cloud {
    from {right:0px;}
    to {right: 88%;}
  }
  @keyframes cloud {
    from {right:0px;}
    to {right: 88%;}
  }

  .progess-wrp{
      margin-top: 0%;
      background-color: #ddd;
  }
  .flight-progress-wrp .MuiLinearProgress-root {
        margin: 6px 0px !important;
        height: 2px !important;
        background-image: linear-gradient(90deg, transparent, transparent 50%, #ccc 50%, #ccc 100%), linear-gradient(90deg, #ddd, #ddd);
        background-size: 10px 2px, 100% 2px;
        border: none;

    }
   .flight-progress-wrp .MuiLinearProgress-bar{
       height: 2px !important;
       background-image: linear-gradient(90deg, transparent, transparent 50%, #fff 50%, #fff 100%), linear-gradient(90deg, #ddd, #ddd);
       background-size: 10px 2px, 100% 2px;
   }

    .dashed-line{
        display: block;
        border: 0.4px solid #ccc;
        background-color: #808080;
    }
    .share-cross-btn svg{
        font-size: 17px !important;
    }
    /* .reset-btn{
        text-align: right;
        border-bottom: 0.3px solid #444;
    } */

    .reset-btn {
        position: fixed;
        text-align: right;
        /* border-bottom: 0.3px solid #444; */
        background: #000;
        right: 25px;
        z-index: 99;
        margin-top: -15px;
        padding: 5px 0px;
    }

    .reset-btn button , .reset-btn button:hover{
        color: #fff;
        font-size: 12px;
        padding: 0px 0px 4px;
        font-weight: 600;
    }

    .groupfareG{
        width: 20px;
    }

    .coin-img {
        width: 25px;
    }


    .share-btn:hover .coin-img {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: rotate3d 2000 linear infinite;
                animation: rotate3d 2000 linear infinite;
        transition: all .8s;
    }

    .white-bg{
        background: #fff !important;
        border: white !important;
    }

    .text-black{
        color: #000 !important;
    }


    .btn-danger, .btn-danger:focus {
        color: #fff;
        background-color: #c82333 !important;
        border-color: #bd2130 !important;
    }

    .MuiButton-containedPrimary{
        background: #46143d !important;
        padding: 4px 20px !important;
        box-shadow: none !important;
        font-weight: 600 !important;
        border-radius: 20px !important;
        color: #fff !important;
    }

    .MuiButton-containedSuccess {
        background: #4caf50 !important;
        padding: 4px 20px !important;
        box-shadow: none !important;
        font-weight: 600 !important;
        border-radius: 20px !important;
        color: #fff !important;
    }

    .text-banner1{
        color: #46143d;
        font-family: 'Mochiy Pop One', sans-serif !important;
        font-size: 20px;
        margin-top: 10px;
    }

    .text-banner2{
        font-size: 14px;
        margin: 0;
    }

    .sports-container{
        padding: 10px 5px 30px;
    }

    .sports-img img{
        width: 100%;
        height: 200px;
        object-fit: cover
    }

    .sports-img{
        position: relative;
        overflow: hidden;

    }
    .sports-title{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 200px;
        width: 100%;
        z-index: 99;
    }
    .sports-title h1{
        font-size: 25px;
    }
    .sports-img img{
        transition: all 0.5s ease-out;
        -webkit-backface-visibility: hidden;
    }
    .sports-img:hover img{
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: all 0.5s ease;
        -webkit-backface-visibility: hidden;
    }

    .card-img img{
        width: 100%;
        height: 200px;
    }

    .card-heading{
        background: #c7a267;
        margin: 0;
    }

    .card-heading h6{
        padding: 8px;
        margin: 0px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
    }

    .card-details {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
        transition: 0.3s;
        width: 100%;
        padding: 15px 15px 5px;
        min-height: 140px;
        position: relative;
    }
      
    .card-details:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .card-details h1{
        font-size: 19px;
        margin: 0;
        margin-bottom: 5px;
    }

    .activity-list .card-details h1{
        font-size: 18px;
        margin: 0; 
        margin-bottom: 5px;
    }

    .card-img{
        position: relative;
    }

    .activity-list .card-img{
        padding-top: 20px;
        padding-right: 20px;
    }

    .activity-countries  .card-img img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    .activity-countries .card-details h1 {
        font-size: 22px;
        font-weight: 400;
        color: #444;
        margin-bottom: 0;
    }
    .activity-countries .card-details a {
        color: #c7a267 !important;
        font-size: 13px;
    }
        
      .activity-countries .card-img{
        padding-right: 0px;

      }
      .card-details h2{
        font-size: 13px;
        font-weight: 400;
        /* margin-bottom: 15px; */
      }

      .card-details a{
        color: #c7a267 !important;
        font-size: 16px;
        display: inline-block;
        cursor: pointer;
        /* position: absolute; */
      }

      .react-multi-carousel-item{
        margin-right: 10px;
      }

      .react-multi-carousel-list {
        height: 350px;
      }

    .twelve{
        position: relative;
        width: -webkit-max-content;
        width: max-content;

    }
    .three{
        width: 100%;
    }
    .three h1 {
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0;
        padding-bottom: 10px;
        position: relative;
        color: #46143d;
      }
      .three h1:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5px;
        width: 55px;
        background-color: #c7a267;
      }
      .three h1:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 2px;
        height: 1px;
        width: 95%;
        max-width: 205px;
        background-color: #c7a267;
      }



    .horizontal{
        margin-top: 6%;
        padding-left: 25px;
        padding-right: 10px;
    }
        
    .dealwrapper { max-width: 320px; background: #ffffff; border-radius: 8px; box-shadow: 0px 0px 50px rgba(0,0,0,0.15); position: relative;}
    .list-group { position: relative; display: block; background-color: #fff; border-radius: 5px;}
    .list-group h4 { font-size: 17px; margin-top: 6px;  margin-bottom: 10px;}
    .list-group p { font-size: 13px; line-height: 1.4; margin-bottom: 2px; font-style: italic;}
    .list-group-item { display: block; padding: 15px 20px 15px 15px !important; border: 1px solid rgba(221, 221, 221, 0.25); text-decoration: none;}
    .list-group-item .heading { color: #141f31;}
    .list-group-item .text { color: #272727;}
    .list-group-item.active .heading, .list-group-item.active .text { color: #ffffff;}

    .ribbon-wrapper { width: 88px; height: 88px; overflow: hidden; position: absolute; top: -3px; right: -3px; z-index: 1;}
    .ribbon-tag {font-size: 13px; text-align: center; -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); position: relative; padding: 6px 0; left: -4px; top: 15px; width: 120px; color: #ffffff; box-shadow: 0px 0px 3px rgba(0,0,0,0.3); text-shadow: rgba(255,255,255,0.5) 0px 1px 0px; background: #343434; }

    .ribbon-tag:before, .ribbon-tag:after { content: ""; border-top: 3px solid #50504f; border-left: 3px solid transparent; border-right: 3px solid transparent; position:absolute; bottom: -3px;}
    .ribbon-tag:before { left: 0;}
    .ribbon-tag:after { right: 0;}

    .dealwrapper.purple .ribbon-tag { 
        background: rgb(185 151 85);
        background: linear-gradient(to bottom, rgb(185 151 85) 0%, rgb(184 150 86) 50%, rgb(179 144 85 / 76%) 51%, rgb(184 150 86) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb60b3', endColorstr='#db36a4', GradientType=0 );}


    .purple .list-group-item.active, .purple .list-group-item.active:focus, .purple .list-group-item.active:hover { background: linear-gradient(to bottom right, #46143e, #9d8152); border-color: #bc9953;}
    /* .purple .list-group-item.active, .purple .list-group-item.active:focus, .purple .list-group-item.active:hover { background: linear-gradient(to bottom right, #ec407b, #ff7d94); border-color: #ff7d94;} */
    .red .list-group-item.active, .red .list-group-item.active:focus, .red .list-group-item.active:hover { background: rgba(248,80,50,1); border-color: #e23819;}
    .blue .list-group-item.active, .blue .list-group-item.active:focus, .blue .list-group-item.active:hover { background: rgba(73,73,250,1); border-color: #2525e0;}

    .list-group-item.active h5{
        font-size: 14px;
    margin-top: 10px;
    color: #fff;
    }
    .list-group-item.active h6{
        font-size: 14px;
        color: #f3e463;
        font-weight: 400;
        
    }

    .speech-box {
        position: relative;
        background: #f3e463;
        width: 345px;
        height: 170px;
        margin: -15px;
        transition: .2s linear;
        -webkit-clip-path: polygon(12% 12%, 70% 10%, 68% 45%, 44% 44%, 48% 61%, 30% 44%, 7% 45%);
      }
      .speech-box:hover {
        width: 340px;
        height: 170px;
      }
      .speech-box h1 {
        position: absolute;
        display: inline-block;
        top: 30px;
        left: 50px;
        font-size: 14px;
        transition: all .2s linear;
      }
      .speech-box:hover h1 {
        font-size: 13px;
      }

      .list-group-inner{
        margin-top: -65px;
      }

      .animate-charcter
        {
        text-transform: uppercase;
        background-image: linear-gradient(
            -225deg,
            hsl(253, 50%, 14%) 0%,
            #f435d1 29%,
            #ff1361 55%,
            #000 100%
        );
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-animation: textclip 2s linear infinite;
                animation: textclip 2s linear infinite;
        display: inline-block;
            font-size: 10px;
        }

        @-webkit-keyframes textclip {
        to {
            background-position: 200% center;
        }
        }

        @keyframes textclip {
        to {
            background-position: 200% center;
        }
        }

    .sliderrange{
        color: #46143d;
    }
    
    .breadcrumb-custom{
        padding: 8px 0px 4px;
    }

    .breadcrumb-custom nav{
        max-width: 1220px;
        font-size: 12.5px;
        margin: auto;
    }
    .breadcrumb-custom nav svg{
        font-size: 15px !important;
    }
    .breadcrumb-custom nav li a{
        color: #878ba1 !important;
        cursor: pointer;
    }
    .breadcrumb-custom nav li p{
        font-size: 12.5px;
    }
    .title-2{
        width: 100%;
    }
    .title-2 h1{
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .Mui-checked{
        color: #46143d !important;
    }

    textarea{
        width: 100%;
        padding: 10px;
        font-size: 15px !important;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    

/* Responsive CSS Starts */

@media (max-width: 1370px){
    button.rc-menu-button.upadtedmenuitems {
        font-size: 15px;
    }
    .balanced-view {
        font-size: 12px !important;
    }
    .dropdown-profile {
        width: 150px;
    }
    button.profile {
        font-size: 11px !important;
    }

}

@media (max-width: 1300px){


    .slick-next {
        right: -20px !important;
    }

    .slick-prev {
        left: -30px !important;
    }

    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
}

/* Responsive CSS Starts */
@media (min-width: 1500px) {
    .banner-text {
        padding: 4% 0px 0px !important;
    }
}

@media (max-width: 1370px){
    button.rc-menu-button.upadtedmenuitems {
        font-size: 15px;
    }

    .balanced-view {
        font-size: 12px !important;
    }

    .dropdown-profile {
        width: 150px;
    }
    button.profile {
        font-size: 11px !important;
    }

}

@media (max-width: 1300px){


    .slick-next {
        right: -20px !important;
    }

    .slick-prev {
        left: -30px !important;
    }

    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
}
@media (min-width: 2000px) {
    
}

@media (max-width: 1199px){
   
    .viewinputhotel{
        font-size: 13px !important; 
    }
    .supplier-txt{
        font-size: 10px !important;
    }
    button.btnmarkup {
        margin-top: 10px;
    }
    .slick-track {
        -webkit-transform: translate3d(0, 0, 0) !important;
                transform: translate3d(0, 0, 0) !important;
    }
    .auth-wrapper {
        max-width: 1400px !important;
    }
    .box-prst {
        width: 100% !important;
        height: 142px !important;
    }
    .ico-box, .ico-box2 {
        width: 100% !important;
    }
    label.itemsradio span {
        font-size: 10px !important;
    }
    .share-cbx{
        width: auto !important;
    }

    .available-seats {
        font-size: 8px !important;
        display: block !important;
        width: 83px !important;
    }
    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
    .timing-item {
        font-size: 11px!important;
        padding: 5px !important;
    }
    .share-cbx-round {
        margin-top: 22px !important;
    }
    .flight_details_btn {
        width: 100% !important;
    }
    .highlight-price {
        width: -webkit-max-content;
        width: max-content;
    }
    .icon-flight {
        width: 40px !important;
    }
}

@media (max-width: 1024px){
    .share-checkbox {
        padding: 2px !important;
    }
   
    .btn-booking-roundtrip {
        min-width: 90px;
        font-size: 13px !important;
        margin-left: 25px!important;
    }
    .white-bg-xs {
        background-color: #fff;
    }
    .btnview {
        font-size: 10px!important;
    }
    .flexset {
        padding: 0 0px !important;
    }
    .flightsearch-box {
        display: block !important;
    }
    svg.flighticonmodify {
        font-size: 18px !important;
    }
    .timing-item {
        font-size: 10px!important;
        padding: 6px !important;
    }
    button.btnBook {
        float: right;
        width: 40% !important;
    }
    .timing-item {
        font-size: 9px!important;
    }
    .swipebox_modify {
        top: 46% !important;
    }
    .supplier-txt{
        display: block;
    }
}


@media (min-width: 1300px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl , .MuiContainer-maxWidthLg{
        max-width: 1240px !important;
    } 
    .showFixedFilter {
        max-width: 265px !important;
    }
}

@media (max-width: 1250px){
    .showFixedFilter {
        max-width: 260px !important;
    }
}
@media (max-width: 1250px) and (min-width: 787px){
  
    .container, .container-lg, .container-md, .container-sm, .container-xl , .MuiContainer-maxWidthLg, .container-fluid{
        max-width: 100% !important;
    } 
    .btnSend{
        width: -webkit-max-content !important;
        width: max-content !important;
    }
    nav.NavbarHeader.navbar.navbar-expand-lg.navbar-light {
        padding: 0.8rem 0rem !important;
    }
    .special-offer-right .flightmininame {
        width: 75px !important;
    }
}

@media (max-width: 1150px) {
    .showFixedFilter {
        max-width: 240px !important;
    }
}

@media (max-width: 786px){
    .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .tlt-range {
        flex-basis: 100%;
    }
    .activity-list .card-details h1 {
        font-size: 16px;
    }
    .MuiMenu-list li {
        display: flex !important;
        padding: 5px 20px !important;
        font-size: 14px !important;
        min-height: 30px !important;
    }
    .card-details a {
        font-size: 14px;
    }
    .breadcrumb-custom {
        padding: 8px 15px 4px;
    }
    
    svg.MuiSvgIcon-root {
        font-size: 16px !important;
    }
    .white-bg-xs{
        padding: 0px !important;
    }
    .box-prst {
        width: 100% !important;
        height: auto !important;
    }
    button.tabTilte {
        font-size: 11px !important;
    }

    .arrowtabinfo img {
        left: 4px !important;
    }
    .closebox {
        top: -12px !important;
        right: -7px !important;
    }

    .setinputBox{
        margin-bottom: 10px;
    }

    .gstinputfield  {
        width: 100%;
    }

    .img-ico {
        height: 95px !important;
    }
    .tltprofilename {
        font-size: 20px !important; 
    }
    span.tltnamegray {
        font-size: 16px !important;
    }
    .paginationbox{
        width: 100% !important;
    }
    .tlt-content-ledger {
        margin-bottom: 0px !important;
        margin-top: 15px;
    }
    thead.dataheadoftable td {
        font-size: 11px !important;
    }
    .calendarBox-airpassenger {
        width: 100% !important;
    }
    .balanced-view{
        margin: 10px;
    }

    .viewbtndetails {
        margin-top: 15px !important;
    }
    .viewdropdown {
        left: 0px !important;
    }

    .header{
        padding:0px !important;
    }
    .scrollingdiv {
        height: 775px !important;
        overflow: hidden !important;
        padding-right: 0 !important;
    }
    
    .prstColr{
        padding-left: 8px!important;
    }

    svg.locationiconr {
        left: 12px;
    }
    div#nav-tabpanel-2 {
        width: auto;
    }

    .box-tlt {
        margin-bottom: 0px!important;
    }

    .swipebox {
        position: absolute;
        top: 46%;
        margin-top: -18px !important;
        z-index: 1;
        right: 0px;
    }

   .Subtlt-traveller {
        padding: 8px 0px !important;
    }
    
    .flightsearch-box {
        display: flex !important;
    }

    .MuiTab-textColorPrimary {
        font-size: 13px;
    }

    .modifysearchbox {
        width: 100% !important;
        margin-top: 21%; 
    }

    .modifybtnhotel{
        margin-bottom: 10px;
    }
    .supplier-txt{
        display: block;
        width: -webkit-max-content;
        width: max-content;
        margin: 15px;
    }
    .linklist-supplier {
        margin: 5% auto !important;

    }
    .MuiTextField-root {
        width: 100%;
    }

    .updateblncBtn button {
        font-size: 13px !important;
        padding: 4px 7px !important;
    }
    .requesttype {
        padding: 15px !important;
    }

    input[type="file"] {
        padding: 5px 3px 10px!important;
    }
    .tltleft {
        font-size: 16px !important;
        flex-basis: 34% !important;
    }
    .icondefine {
        flex-wrap: wrap;
    }
    .top-recharge{
        padding-left: 8px;
        margin-top: 10px;
    }
    .tlttxtset {
        flex-basis: 40% !important;
    }
    .txtsetinput {
        width: 100% !important;
    }
    .boxamountwidth {
        width: 100% !important;
    }

    .uploadBtn{
        margin-left: 9px;
    }

    label.rnditemsradio span:first-child {
        left: -47px !important;
    }
    .layover_rd {
        font-size: 10px !important;
        padding: 6px 6px !important;
        left: 35% !important;
     }
    span.roundtoname {
        font-size: 11px !important;
    }
    .roundflightairportaddress {
        padding-left: 15px !important;
    }

    .roundarrowtabinsidebox img {
        top: 5px !important;
        width: 36px !important;
        right: 22px !important;
    }
    .roundflightairportaddress p {
        margin-bottom: 5px !important;
    }
    .fc .fc-view-harness{
        height: 100vh !important;
        overflow: auto !important;
    }

    .fc .fc-scrollgrid-liquid {
        width: 1120px !important;
        overflow: scroll;
    }
    .calendarBox{
        width: 100% !important;
    }
    .fc .fc-daygrid-body {
        width: 100% !important;
    }
    .fc .fc-toolbar-title {
        font-size: 20px !important;
    }
    .alignleft{
        display: block !important;
        padding: 0px 15px !important;
    }
    .roundtltBaggage {
        font-size: 9px !important;
    }
    .btn-booking-roundtrip {
        min-width: 76px!important;
        font-size: 12px !important;
        padding: 2px !important;
    }
    .bookingflightroundtrip {
        bottom: 7% !important;
        z-index: 9999 !important;
        border-top: 1px solid #fff;
    }
    .bgcolor {
        padding: 15px 0px !important;
    }

    .swipeboxModify-res{
        top: 32% !important;
        left: 45% !important ;
    }

    .MuiTab-textColorPrimary {
        font-size: 11px !important;
    }
    .roundtripresult-mob .filter-msg-output {
        font-size: 12px !important;
        padding: 20px 0px 0px !important;
    }
    .filter-msg-output {
        font-size: 12px !important;
        padding: 10px 0px 0px !important;
    }
    .r-table-container {
        overflow-x: auto !important;
    }
    .filter-btn-float .btn-primary, .filter-btn-float .btn-primary:hover {
        color: #fff;
        background-color: #46143d !important;
        border-color: #46143d !important;
        font-size: 14px;
        font-weight: 400;
        padding: 12px;
        outline: none !important;
        box-shadow: none !important;
    }

    .filter-btn-float  {
        position: fixed;
        background: #46143d;
        width: 100%;
        bottom: 0;
        left: 0;
        text-align: center;
        z-index: 99;
        border: none;
        border-radius:0px ;
    }
    .filter-mob ,.sortby-mob {
        position: fixed !important;
        height: 100vh;
        width: 87% !important;
        overflow: auto;
        z-index: 99;
        display: none;
        background: #fff;
        padding: 5% 10px 50% 10px !important;
    }
    .d-block-xs{
        display: block !important;
    }
    .btn-primary {
        color: #fff;
        background-color: #fc9f14;
        border-color: #fc9f14;
    }
    .flighttltname {
        font-size: 10px !important;
    }
    .flightsubtltname {
        font-size: 8px !important;
    }
    .flightsearchmodify-btn button {
        font-size: 11px !important;
        padding: 4px 2px !important;
        font-weight: 600 !important;
        border: none !important;
    }
    .flightsearchmodify-btn svg{
        font-size: 12px !important;
    }
    .modify-col{
        border-right:0px !important ;
    }
    .flex-servicesbox {
        width: 107% !important;
    }
    .flighttabsdetails-link {
        padding: 0px 5px !important;
        font-size: 13px !important;
    }
    .tltBaggage-desc {
        font-size: 12px !important;
    }
    .layover {
        font-size: 10px !important;
        top: -11px !important;
        left: 32% !important;
    }
    span.icoarrowfromto img {
        width: 18px !important;
        opacity: 1 !important;
    }

    .flightsearchmodify-btn {
        text-align: center;
    }
    .flight-details-mob {
        display: none !important;
    }
    .bookingflightroundtrip{
        padding: 8px 0px !important;
    }
    .flight_details_btn{
        width: 100% !important;
        margin-top: 10px;
    }
    .flight_details_oneway::after, .flight_details_round::after{
        background-color: transparent !important;
    }
 
   
    .modify-section {
        justify-content: flex-start !important;
    }
    .footerbookingflightnumber {
        font-size: 10px !important;
    }
    h3.tlt-lable {
        font-size: 18px !important;
    }
    .tlt-depart {
        font-size: 17px !important;
    }
    .date-depart {
        font-size: 10px !important;
        margin-top: 0px !important;
    }
    .abstlt {
        font-size: 17px !important;
    }
    .MuiDialog-paperScrollPaper {
        margin: 5px !important;
        width: 100% !important;
    }
    .btnmarkup{
        margin-top: 10px !important;
    }
    .updateTlt_hotel {
        font-size: 11px !important;
    }

    .viewinputhotel {
        font-size: 10px !important;
    }
    button.updateBtnHotel {
        font-size: 9px !important;
        padding: 4px 0px !important;
    } 
    .offernpricedtls {
        margin-top: 0px !important;
    }
    .ratedetailcard {
        width: 100% !important;
        padding: 10px !important;
        margin-left: 0px !important;
    }
    .roomrent {
        padding: 10px 0px !important;
        font-size: 15px;
        text-align: left !important;
    }
    .cancellationboxhotel {
        padding: 0px 0px 10px !important;
    }
    .roombox {
        padding: 15px 0px !important;
    }
    .btnbookhotel {
        padding: 15px 0px !important;
    }
    .fc-theme-standard td, .fc-theme-standard th{
        width: 130px !important;
    }
    .sideimgboxleft {
        padding-right: 0px !important;
    }
    .slideimgbox {
        width: 100% !important;
        margin-top: 55px !important;
    }
    .colspacemini {
        padding: 0px 15px !important;
    }
    .galleryslider .slick-slide {
        height: auto !important;
    }
    .galleyheader{
        width: 100% !important;
    }
    .tabcontentsbox .roundmini-tag {
        margin-left: 0px !important;
        margin-bottom: 10px;
    }
    .roundtripresult-des{
        display: none;
    }
    label.rnditemsradio span {
        margin-left: 30px !important;
    }

    .roundtripdetails-mini .roundflightimg {
        display: block !important;
        align-items: flex-end;
    }
    .roundflightdetailscard {
        /* padding-left: 12px !important; */
        padding: 10px 0px 0px !important;
    }
    .flightminitime {
        font-size: 13px !important;
    }
    .arrow-img-round img {
        top: 17px !important;
        width: 13px !important;
        left: 56% !important;
    }

    ul.roundlisticon {
        display: none !important;
    }
    .roundflightimg {
        display: block !important;
    }
    .roundarrow-img img {
        left: 4px !important;
        width: 20px !important;
    }
    .round-radio-fieldwith-iconlist {
        padding-left: 0px !important;
    }
    label.rnditemsradio span {
        margin-left: 21px !important;
        margin-top: -1px;
    }
    .tltrndheader {
        color: #46143d;
        font-size: 14px;
    }

    .roundtripdetails-mini label.rnditemsradio span{
       display: none;
    }
    .roundtripdetails-mini ul.roundservicesBox {
        margin: 5px 10px;
    }
    .roundtltBaggage-desc {
        font-size: 10px !important;
    }

    .define-timecalc {
        font-size: 8px !important;
        padding-top: 4px !important;
    }
    .roundtripdetails-mini .flagflt {

    }
    .roundflightdetailscard-xs {
        padding: 10px 7px 5px;
        background: #fff;
        border-radius: 0px 10px 10px 0px;
        margin-bottom: 15px;
        box-shadow: -8px 3px 5px 1px rgb(0 0 0 / 15%);
    }
    .define-stop {
        font-size: 8px !important;
    }
    button.mailbtn, button.selectbtnOutline {
        padding: 2px 5px !important;
    }
    .round-highlight-price {
        font-size: 14px !important;
    }
    .view-more-btn{
        padding: 0px !important;
    }
    .flighttimexs{
        font-size: 11px !important;
    }
    .flexset-rnd-inside {
        padding: 0px 10px !important;
    }
    .flightmininame {
        font-size: 9px !important;
    }
    .flightminidata {
        font-size: 8px !important;
    }
    .rndflightname {
        font-size: 10px !important;
        width: -webkit-max-content;
        width: max-content;
    }
    .rndflightnumber {
        font-size: 11px !important;
        width: -webkit-max-content;
        width: max-content;
    }
    .share-cbx-round {
        margin-top: 6px !important;
    }
    .roundtripdetails-mini .roundmini-tag {
        margin: 5px 0px !important
    }
    .tltrndheader{
        white-space: normal !important;
        white-space: initial !important;
    }
    .roundflightdetailscard {
        margin-bottom: 10px !important;
    }
    .scroll-roundtripresult{
        height: 100vh !important;
        overflow: scroll !important;
    }
    .share-container {
        bottom: 44px !important;
        width: 250px !important;
        padding: 10px 15px !important;
    }
    .share-ttl-1 {
        margin-bottom: 0px important;
        font-size: 11px !important;
    }
    .share-outline-input {
        margin-top: 0px !important;
    }
    .share-row-wrapper {
        margin-top: -8px !important;
    }
    .arrowimg-mob{
        top: 5px !important;
    }
    
    .flightairportaddress {
        padding: 0px 15px !important;
    }
    .viewresultbooking {
        padding: 5px 0px !important;
    }
    .highlight-price {
        font-size: 16px !important;
    } 
    .flightnumber {
        width: -webkit-max-content;
        width: max-content;
        font-size: 10px !important;
    }
    .departflightminitime {
        font-size: 15px !important;
    }
    .departflightname {
        font-size: 13px !important;
    }
    .departflightnumber {
        font-size: 9px !important;
    }
    .departimgminifly {
        margin-right: 12px !important;
    }
    .departdefine-stop , .departdefine-timecalc {
        font-size: 10px;
    }
    .baggagedepart {
        padding: 15px 0px !important;
    }
    .departcode {
        font-size: 18px !important;
    }
    .baggageBox {
        margin-left: -10px !important;
    }
    .items-head {
        padding: 0px 15px !important;
    }
    span.boldtxt {
        font-size: 14px !important;
    }
    .definedepartpwidth {
        margin-top: 10px;
    }
    .table-mob{
        min-width: 100% !important;
    }
    .share-text-input input{
        margin-top: 0px !important;
    }
    .choosebtn {
        margin-left: 0px !important;
    }
    .para-txt{
        padding: 10px !important;
    }
    .insideRow {
        padding: 0 0% !important;
    }
    .itemRow {
        padding: 20px 10px !important;
    }
    button.btnsubmitchange {
        min-width: 100px !important;
    }
    .tltprofilename {
        margin-left: 0% !important;
    }
    .balance_show {
        z-index: 9;
    }
    .tbleBox {
        display: block !important;
    }
    .tblecelltlt {
        width: 100% !important;
        text-align: left !important;
    }

    .titlepricetag {
        flex-basis: 30% !important;
    }
    label.itemsradio span {
        font-size: 11px !important;
    }
    li.listitemsicon img {
        margin: 5px 2px  !important;
    }
    .share-cbx {
        margin-top: 10px;
        width: -webkit-max-content !important;
        width: max-content !important;
    }
    .setbtnprevnext {
        width: 100% !important;
        justify-content: start !important;
    }
    .more-flights-btn, .view-more-btn {
        font-size: 11px !important;
        font-weight: 600 !important;
    }
    .roundmini-tag {
        margin-left: 1px !important;
    }
    .flighttabsdetails .MuiTab-textColorPrimary, .flighttabsdetails .MuiTab-textColorPrimary.Mui-selected {
        min-width: 58px !important;
        width: 58px !important;
        padding: 0px 5px !important;
        font-size: 11px !important;
        min-height: 50px
    }
    .moreOption{
        padding: 10px 0px;
    }
    .flightsearchmodify-btn {
        text-align: right !important;
        padding: 5px !important;
    }
    .onewaycontainer .flightmininame {
        font-size: 9px !important;
    }
    .onewaycontainer .share-cbx {
        margin-top: 10px !important;
        margin-left: 35% !important;
        top: -10% !important;
    }
    .filter-btn-float svg.MuiSvgIcon-root {
        margin-top: -2px;
    }
    .sortby-des{
        display: none !important;
    }

    .sortby-label {
        font-size: 14px  !important;
        color: #000  !important;
    }
    .sortby-mob .MuiFormControlLabel-label{
        font-size: 14px;
        font-weight: 500;
    }
    .sortby-mob .MuiRadio-colorPrimary {
        padding: 0px 10px !important;
    }
    .sortby-mob .viewresultbooking.addonbackground.row {
        background: #fff !important;
    }
    .sortby-radiogrp{
        align-items: end;
    }
    .sortby-radiogrp label{
        margin: 2px 0px !important;
    }
    .sort-by-border{ 
        border-bottom: 1px solid #ccc;
        padding: 20px 0px;
    }
    .roundtltfaredetails {
        font-size: 11px !important;
    }
    .roundtlt-total-right{
        text-align: right;
    }
    /* .MuiNativeSelect-select {
        padding: 0px 0 5px !important;
    } */
    .roundtabsBox {
        margin: 2px 10px !important;
    }
    .onewaycontainer .flighttabsdetails .MuiTab-textColorPrimary, .onewaycontainer .flighttabsdetails .MuiTab-textColorPrimary.Mui-selected {
        min-width: 80px !important;
        width: 85px !important;
        text-align: center;
        font-size: 9px !important;
        padding: 10px !important;
    }
    .onewaycontainer .arrowtabinfo img {
        left: 13px !important;
        width: 30px;
    }
    .onewaycontainer .flightairportaddress {
        padding: 0px 0px !important;
    }
    .onewaycontainer .arrowtabtxt {
        font-size: 9px !important;
    }
    .onewaycontainer .tltdate {
        margin-top: 0px !important;
    }
    .onewaycontainer .define-timeinfo{
        padding-top: 12px !important;
    }
    .more-flights-btn {
        padding: 0px !important;
    }
    .tlt-total-left{
        text-align: left !important;
    }
   
    .definewidth {
        margin: 0px !important;
    }
    .Subtlt-traveller {
        font-size: 10px !important;
        padding: 2px 0px !important;
    }
    .px-xs-0{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .pl-xs-0{
        padding-left: 0px !important;
    }
    .pr-xs-0{
        padding-right: 0px !important;
    }
    .pt-xs-3{
        padding-top: 15px !important;
    }
    .pb-xs-3{
        padding-top: 15px !important;
    }
    .sideimgboxright {
        display: flex !important;
    }
    .arrowtabtxt {
        font-size: 10px !important; 
    }
    .arrowbox-multi{
        width: 50px !important;
    }
    .multi-flighdatetime{
        text-align: center;
    }
    button.seatbtnpopup {
        font-size: 10px !important;
        min-width: 110px !important;
    }
    .inputBoxgst{
        margin-top: 10px;
    }
    .tlt {
        font-size: 15px !important;
    }
    .faredetailprice {
        font-size: 16px !important;
    }
    .dailogBox-tlt {
        display: block !important;
    }
    .dropdown-profile {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .login-right-mob{
        display: none !important;
    }
    .logo-account {
        margin-left: 0px !important;
    }
    .header-logo {
        width: 65% !important;
        padding-top: 15px !important;
    }
    .navbar {
        padding: 1.5rem 1rem !important;
    }
    .viewroundresultbooking{
        padding: 3px 0px !important;
    }
    .viewroundresultbooking .MuiNativeSelect-select {
        font-size: 13px !important;
        padding: 0px!important;
    }
    .faretype-p {
        font-size: 13px;
    }
    .swipebox_modify{
        z-index: 9 !important;
    }
    .tabsBox .flightimg {
        display: block !important;
    }
    .roundviewbtndetails {
        margin-top: 4px !important;
    }
    .btnview {
        font-size: 10px!important;
        width: -webkit-max-content !important;
        width: max-content !important;
        padding: 5px 7px!important;
    }
    .arrowmultiImg img{
        left: 5px !important;
    }
    .multiarrowimg{
        top: 5px !important;
    }
    button.btnEditProfile {
        font-size: 12px !important;
    }

    .hoteldsgn {
        max-width: 100% !important;
    }
    button.btntrnlog{
        min-width: 100px !important;
    }
    .share-container, .share-container-round {
        bottom: 44px !important;
        width: 250px !important;
        padding: 10px 15px !important;
    }
    .imgdepartbooking {
        display: block !important;
    }
    .countrycode{
        width: auto !important;
    }
    .sports-container {
        padding: 10px 5px 20px;
    }
    .subtlt-fly {
        font-size: 11px !important;
    }
    .tlt-fly {
        font-size: 16px !important;
    }
    .MuiMenu-list li {
        width: 100% !important;
    }
    .offline-total-ttl{
        padding: 0px 8px;
        font-size: 17px;
    }
    .Fdetails {
        font-size: 12px !important;
    }
    .Udetails {
        font-size: 13px !important;
    }
    .selecthoteltabs .MuiTab-root {
        min-width: 120px !important;
    }
    .MuiTabs-scroller {
        overflow: auto !important;
    }
    .share-txtbtn {
        font-size: 11px !important;
    }
    .searchtab-main {
        width: 100% !important;
        position: relative !important;
    }
    .search-box .MuiTabs-flexContainer {
       display: -webkit-box !important;
    }
    .div-border-right {
        display: none;
    }
    .search-wpr .box-input-calender {
        width: 100% !important;
    }
    .search-wpr .box-input-passclass {
        width: 100% !important;
    }
    .box-tlt {
        font-size: 10px !important;
    }
    .MuiButton-root {
        float: revert;
    }
    .search-wpr {
        display: flex;
        padding: 10px !important;
        border-radius: 20px;
        background: #fef7f8;
    }
    .search-wpr .mob-input-border{
        /* border: 1px solid #ccc; */
        border-radius: 10px ;
        margin-bottom: 10px;
        display: block !important;
    }
    .search-wpr .mob-input-border-r{
        /* border: 1px solid #ccc; */
        border-radius: 10px ;
        margin-bottom: 10px;
        display: block !important;
    }
    .modifydata .MuiTab-root {
        font-size: 13px !important;
        padding: 0px !important;
    }
    .search-box .MuiTabs-scroller {
        /* overflow: hidden !important; */
    }
    .search-wpr .box-input-w {
        padding-top: 10px !important;
    }
    .search-wpr .inputFieldLocation{
        margin-bottom: 2px!important;
    }
    .searchtab-main .MuiTab-textColorPrimary{
        display: revert !important;
        padding: 0px 20px !important;
    }

    .modifydata .MuiTabs-flexContainer{
        width: 100% !important;
    }
    .m-inherit {
        margin: unset !important;
    }
    .footerimg img {
        width: 50% !important;
    }
    .listlinkitemsbox {
        display: block !important;
        margin-top: 0px !important;
    }
    .footeraddress p {
        margin-bottom: 10px !important;
        margin-top: 0px !important;
    }
    .linklistul ul {
        font-weight: 500;
        padding: 0px 10px !important;
    }
    .banner-bg-image {
        background-size: cover !important;
    }
    .horizontal {
        margin-top: 5%;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .footer {
        padding: 20px 0px 20px !important;
    }
    .banner-des-1, .banner-des-2, .banner-des-3, .banner-des-4, .banner-des-5{
        width: 100% !important;
        font-size: 13px !important;
    }
    .banner-1, .banner-2, .banner-3, .banner-4, .banner-5{
        padding-top: 50px !important;
    }
    .navbar {
        padding: 0.8rem 1rem !important;
    }
    .imgfnd img {
        width: 100%;
    }
    .tltrndheader-flight {
        background: #0a74b9;
        padding: 2px 10px;
        color: #fff;
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .itemssecttag {
        font-size: 8px !important;
    }
    .addon-btn {
        width: 100%;
        margin-left: 0%!important;
    }
    .filter-msg-output-multi{
        padding: 5px 0px !important;
    }
    .setbtnprevnext-multi{
        padding: 0px 10px !important;
    }
    .shownetmulticities {
        margin-top: 3px !important;
        font-size: 14px !important;
    }
    .flexset-intr{
        padding: 0px 30px !important;
    }
    .modify-section-des{
        display: none;
    }
    .modifyheadercontent{
        width: 100%;
        padding: 12px 0px !important;
        position: fixed;
        z-index: 99;
        top: 0px;
    }
    .departcodedetails span {
        font-size: 11px !important; 
    }    
    .travellerdetailsBox {
        padding: 15px !important;
    }
    .sectorToggleBtn {
        font-size: 12px !important;
        padding: 5px 2px !important;
    }
    .sectorToggleBtnActive{
        font-size: 12px !important;
        padding: 5px 2px !important;
    }
    .tlt-traveller{
        display: flex;
    }
    .texticon svg {
        font-size: 43px !important;
    }
    .txt_btn{
        text-align: center !important;
        margin-top: 30px !important;
        justify-content: center;
        display: flex;
    }
    .txt_btn button{
        font-size: 10px !important;
    }

    .tdledger p{
        width: -webkit-max-content;
        width: max-content;
    }

    .voucher-container {
        zoom: 0.35;
    }

    .button.selectbtnOutline{
        margin-top: 10px;
    }
    .sectorToggleBtnActive{
        padding: 5px 15px !important;
    }
    .sectorToggleBtn{
        padding: 5px 15px !important;
    }

    .report-more-btngrp {
        text-align: left !important;
    }

    .baggage-heading li {
        font-size: 12px !important;
    }
    .meal-img img {
        width: 100% !important;
    }
    .meal-price {
        font-size: 13px !important;
    }
    .baggage-img img {
        width: 100% !important;
        padding: 3px !important;
    }
    .tlthtlstr {
        font-size: 18px !important;
    }
    .hoteladdress p {
        font-size: 12px !important;
    }
    .ratedetailcard {
        padding: 20px !important;
    }
    .galleryslider{
        margin-top: 18% !important;
    }
    .tabsPanelContainer{
        padding: 0px !important
    }
    .account-ledger{
        margin: 15px 0px !important;
    }
    .tlt-content-ledger , .table-box-found {
        padding: 0px 15px !important;
    }
    .tlt-ledger {
        padding: 15px 0px !important;
    }
    .searchtab-sp{
        width: 40% !important;
    }
    .offer-bg-img {
        height: 300px !important;
    }
    .special-offer-right .flightmininame {
        font-size: 13px !important;
        text-align: center;
    }
    .special-offer-right .arrowbox {
        width: 14% !important;
    }
    .specailfarelabel {
        padding: 10px 0px;
        font-size: 20px !important;
    }
    .boxdivition-row {
        padding: 15px 0px !important;
    }
    .moreBtnList {
        left: 18% !important;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .balanceBox {
        margin: 0px !important;
    }
    .flight-invoice-container{
        zoom: 0.4;
    }
    .tltfaretype-desc {
        position: relative !important;
        padding: 0px 15px 0px !important;
        right: 0px !important;
        top: 10px !important;
    }
    .search-box-hotel{
        margin-top: -70% !important;
    }
    .special-offer-button{
        height: 300px !important;
    }
    .top-up-footer-spinner .divLoader {
        margin-bottom: 17% !important;
    }
    .top-up-footer-spinner .svgLoader {
        width: 100px !important;
    }
    .banner-text{
       display: none;
    }

    .banner-des-1, .banner-des-2, .banner-des-3, .banner-des-4, .banner-des-5,  .banner-des-6, .banner-des-7, .banner-des-8, .banner-des-9{
        width: 100% !important;
        font-size: 15px !important;
    }
    .btnSend {
        padding: 6px 25px !important;
    }
    .rightBoxContainer {
        padding: 15px 0px 0px !important;
    }
    
    .itemsclockicon svg.MuiSvgIcon-root {
        font-size: 25px !important;
        margin-right: 5px ;
    }
    .header-topup {
        margin-bottom: 15px;
    }
}


@media (max-width: 550px){
    .footeraddress1 p{
        word-break: break-word;
    }
    .flightsearch-box {
        display: flex !important;
        padding: 0px 5px !important;
    }
    
    .icoBox {
        width: 86px !important;
        font-size: 14px !important;
    }
    img.icostatic {
        width: 41px !important;
        height: 41px !important;
    }

    .tlt-services {
        width: 100% !important;
        margin-left:  25px !important;
        padding-right: 15px;
    }
    .tlt-services ul {
        display: flex;
    flex-wrap: wrap;
    }

    .box-prst {
        height: auto !important;
    }

    h2.tlt-link {
        margin-right: 0px !important;
        font-size: 19px !important;
        margin-top: -12px ;
    }
    .loginBox {
        margin: 20px 0px !important;
    }
    .box-prst {
        padding: 10px 30px !important;
    }
    .tlt-services ul li {
        margin: 0 5px;
        width: 100%;
        padding-right: 20px;
    }
    .footeraddress {
        padding: 5px 10px;
    }

    .socialicons {
        margin-bottom: 10px;
    }

    .registerBox {
        margin: 15px 0px !important;
    }

    span.linkcreateaccount {
        display: block;
    }

    .gst_width {
        width: 100% !important;
        padding: 0px 35px !important;
    }

    .gst_width .tlt-form {
        flex-basis: 60% !important;
    }
    .managegstrow_new.row {
        padding: 0px !important;
    }

    .updateblncBtn button {
        margin-right: 10px !important;
    }

    .balncContainer .inputrow{
        padding: 0px 15px !important;
    }

    .Slider-deales-xs{
        margin-top: 5% !important;
    }
    .resulthoteltlt{
        margin: 0px !important;
        padding: 15px 0px !important;
        margin-bottom: 15px !important;
    }
    .tlt-hotel {
        margin-top: 10px;
        margin-bottom: 5px !important;
    }
    .addresshotel p {
        margin-bottom: 5px !important;
    }
    .facilites span {
        font-size: 11px !important;
    }
    
    .hotelrow img{
        object-fit: cover;
    }
    button.hoteltab {
        padding: 0 15px !important;
    }

    .hoteltabs .MuiTabs-scroller {
        overflow: auto !important;
    }
    .tltbooknow button {
        font-size: 10px !important;
    }
    .flexdiv{
        margin-top: 7% !important;
    }
    .sideimgboxright button{
        font-size: 12px !important;
        min-width: 50px !important;
    }
    .overlayposotion {
        top: 4px !important;
        left: 31% !important;
        font-size: 10px !important;
    }
    .define-timecalc, .define-stop{
        text-align: center;
    }
      .three h1 {
        font-size: 17px;
    }
    .three h1:after {
        width: 60%;
    }
    .heading-right {
        flex-basis: 60%;
    }

    .activity-list .card-img {
        padding-top: 20px;
        padding-right: 0px;
    }
    .sports-img img, .sports-title {
        height: 100px;
    }
    .sports-title h1 {
        font-size: 18px;
    }

    .px-xs-3{
        padding: 0 15px;
    }
}

@media (max-width: 375px){

    .btn-collapse button {
        padding: 6px 7px!important;
        font-size: 12px!important;
    }
}

@media (max-width: 330px){
    .flightsearch-box {
        display: block !important;
        padding: 0px 5px !important;
    }

    .footer .container-fluid{
        padding: 0px !important;
    }
    .updateTlt_hotel {
        font-size: 10px !important;
    }

    .setbtnprevnext {
        width: 100% !important;
        padding: 0px 20px 10px !important;
    }

    .icoBox {
        width: 70px !important;
        font-size: 14px !important;
    }

    .search-box .MuiGrid-root {
        display: block !important;
    }
    button.tabTilte {
        min-width: 68px !important;
    }

    .Slider-deales-xs {
        margin-top: 76% !important;
    }
    .flightminitime {
        font-size: 8px !important;
    }
    .round-highlight-price {
        font-size: 8px !important;
        margin-bottom: 3px;
    }
    .share-cbx-round {
        margin-left: -5px !important;
    }
    .flightimg {
        display: block !important;
    }
    
    
}

@media (min-width: 768px) and (max-width: 1000px) {
  
    
    .supplier-txt {
        width: auto !important;
    }
    .prstColr {
        padding-left: 10px!important;
    }
    svg.locationiconr {
        left: 14px !important;
    }
    .addon-btn {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 10px !important;
    }
    .swipebox {
        top: 74px !important;
    }
    .share-cbx {
        width: -webkit-max-content !important;
        width: max-content !important;
    }
  
}

@media (min-width: 787px) {
    .modify-section-xs{
        display: none;
    }
    
    .filter-btn-float{
        display: none;
    }

    .filter-mob{
        display: block !important;
    }

    .btnview {
        width: 100% !important;
        font-size: 10px !important;
    }
    .roundtripresult-mob{
        display: none;
    }
    .sortby-mob{
        display: none;
    }
  
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}


.singleimgbox-1 {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 10px;
}
.singleimgbox-1 img {
    width: 100%;
    object-fit: cover;
    height: 250px;

}
.selectactivity-container .hotelrow {
    margin:  0;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
}

.breadcrumb-custom.margin {
    margin-top: 40px;
}
/* .colspace {
    padding: 10px!important;
} */

.freeCancRow{
    padding: 0px 15px;
}
.colspacemini {
    padding: 0px;
}
.multiimgbox  {
    width: 100%;
}
.multiimgbox img {
    width: 100%;
    height: 160px;
    border-radius: 0px;
    margin-bottom: 15px;
}
.sideimgboxleft {
    width: 100%;
    height: 100%;
}
.sideimgboxright {
    width: 100%;
    height: 100%;
    position: relative;
}

.sideimgboxleft img {
    height: 80px;
}
.sideimgboxright img {
    height: 80px;
}

a.linkviewhotel {
    position: absolute;
    text-align: center;
    top: 50px;
    left: 40px;
    z-index: 9;
    font-size: 16px;
    font-weight: 500;
}
.sideimgboxleft img {
    width: 100%;
    border-radius: 0px;
}
.sideimgboxright img {
    width: 100%;
    border-radius: 0px;
}
.ratedetailcard {
    width: 95%;
    /* height: 45%; */
    padding: 10px;
    margin-left: 13px;
    border-radius: 23px;
    border: 1px solid #808080;
    /* border-bottom: 0px; */
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}
.tltcarddts {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
}

.boxprst {
    display: flex;
    padding: 0px;
    width: 100%;
}
.chargedts {
    flex-basis: 70%;
    text-align: left;
}
.chargeprice {
    flex-basis: 30%;
    text-align: right;
}
.item-rateoff {
    font-size: 14px;
    font-weight: 500;
    text-decoration: line-through;
    color: #999;
}
.item-rateopen {
    font-size: 17px;
    font-weight: 500;
}
.item-frt {
    font-size: 14px;
    font-weight: 500;
    color: #5d5a5a;
}
.appliedbox {
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    color: green;
}
.nonrefundico {
    font-size: 14px;
    font-weight: 500;
}
.nonrefundico svg {
    color: red;
}
.nonrefundico span {
    margin-left: 8px;
}

.nonrefundicochecked {
    font-size: 14px;
    font-weight: 500;
}
.nonrefundicochecked svg {
    color:blue;
}
.nonrefundicochecked span {
    margin-left: 8px;
}
.flexdiv {
    display: flex;
    align-items: center;
    width: 107%;
    padding: 10px;
    border: 1px solid #808080;
    margin: -11px;
    margin-top: 22%;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}
.tltbooknow button {
    font-size: 12px;
    border-radius: 30px;
}
.tltdiv {
    font-size: 14px;
    font-weight: 500;
    flex-basis: 50%;
}
.tltbooknow {
    flex-basis: 50%;
    text-align: end;
}
.tlthotelwithstr {
    display: flex;
    width: 100%;
    align-items: center;
}
.tltstr {
    margin-left: 20px;
}
.tltstr ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.tltstr ul li {
    margin-right: 8px;
}
.tlthtlstr {
    font-size: 20px;
    font-weight: 500;
    color: #0b78bc;
}
.hoteladdress p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
.tabsrow {
    margin: 0;
    margin-bottom: 25px;
    padding: 15px 0px;
    background: #fff;
    border-radius: 10px;
}
button.hoteltab {
    padding: 0;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
}
.tabboxhotel {
    padding: 20px;
}
.desc-tlt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 6px;
}
ul.tabullist {
    list-style-position: outside;
    list-style-position: initial;
    padding-left: 20px;
}
ul.tabullist li {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
}
.paratabtext p {
    font-size: 15px;
    font-weight: 500;
}
.coltabspace {
    padding: 0!important;
}
.roombox {
    padding: 15px;
}
.roomdtlstlt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
.miniico-roomdtls {
    font-size: 13px;
    font-weight: 500;
    color: #565656;
    position: relative;
    padding-left: 25px;
}
.miniico-roomdtls svg {
    position: absolute;
    top: 1px;
    left: 0;
}
.cancellationboxhotel {
    padding: 15px 0;
}
.ltlmode {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
}
.ltlmode svg {
    color: #3BB54A;
    font-size: 22px;
}

.free_cancel {
    font-size: 14px;
    font-weight: 500;
    /* margin-bottom: 10px; */
}
.free_cancel svg {
    color: #3BB54A;
    font-size: 20px;
}
.free_cancel span {
    margin-left: 8px;
}

.ltlmode span {
    padding-left: 4px;
}
span.datesetsw {
    font-size: 14px;
}
button.vewclsbtn {
    font-size: 13px;
    text-transform: lowercase;
    /* margin: 11px 3px; */
}
.msgprovider {
    display: inline-block;
    padding: 5px;
    margin-top: 10px;
    background: #3BB54A;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    cursor: context-menu;
}
.roomrent {
    padding: 15px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
}
.btnbookhotel {
    padding: 15px;
}
.btnbookhotel button {
    width: 100%;
    font-size: 15px;
    padding: 5px;
}
.roomdetailsbookingbox {
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
    margin-left: 5px!important;
    margin-right: 5px!important;
    margin-bottom: 8px!important;
}
.mainaddonbox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.bosaddonfilter {
    padding: 10px 15px;
    margin-right: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
}

label.labelcontroladdoncheck {
    width: 100%;
    margin: 0;
    /* margin-bottom: 10px; */
}
label.labelcontroladdoncheck span {
    padding: 0;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.pointercls{
    cursor: pointer;
}

li.cancel-policy{
    margin-bottom: 0px;
    font-size: 14px;
}

.activity-tab .MuiTab-root {
    min-width: 25% !important;
    padding: 0px 15px;
    font-weight: 600;
    background: #46143d08;
    font-size: 16px;
    border: 1px solid #46143d14;
}

.activity-tab svg.MuiSvgIcon-root {
    font-size: 14px !important;
    /* margin-bottom: 2px; */
    margin-right: 4px;
}

.refreshmentIcon {
    font-size: 14px !important;
    margin-bottom: 2px;
    margin-right: 4px;
}
.inclution-list{
    list-style: none;
    font-size: 12px;
    padding: 0px;
    padding-right: 15px;
    border-right: 1px dotted #ddd;
}
.inclution-list svg{
    color: #fff;
    font-size: 13px !important;
    background: #3aa014;
    margin-top: 4px;
    margin-right: 11px;
}


.inclution-list-simple{
    font-size: 12px;
    padding: 0px;
    margin: 0;
}

.exclution-list{
    list-style: none;
    font-size: 12px;
    padding: 0px;
}

.exclution-list svg{
    color: #fff;
    font-size: 13px !important;
    background: #EB2026;
    margin-top: 4px;
    margin-right: 11px;
}

.inclution-list li, .exclution-list li, .inclution-list-simple li{
    margin-bottom: 6px;
    font-size: 14px;
    display: flex;
}
label.filtersroompage span {
    padding: 0;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.bosaddonfilter {
    padding: 10px 15px;
    margin-right: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
}

label.filtersroompage {
    width: 100%;
    margin: 0;
    margin-bottom: 0px;
}



.act-txt-xs{
    font-size: 12px;
    font-weight: 500;
}

.act-search-input{
    width: 100%;
}

.act-heading-1 {
    font-size: 22px;
    font-weight: 700;
    padding: 20px 0px 10px;
}

.activity-container .flitercolumstick {
    position: static !important;
    position: initial !important;
    top: 0;
}

.actfare-wrapper{
    padding: 10px 30px;
}

.actfare-ttl-1{
    color: #FF0000;
    font-size: 10px;
    margin-bottom: 0;
}

.actfare-ttl-2{
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
}

.actfare-ttl-3{
    font-size: 10px;
    font-weight: 600;
}

.actfare-ttl-11{
    color: #1676b8;
    font-weight: 700;
    margin-bottom: 0;
}

.actfare-ttl-light{
    color: #9e9e9e;
    font-size: 10px;
    margin-bottom: 0;
}

.actfare-ttl-bold{
    margin-top: 10px;
    font-size: 13px;
    color: #000;
    margin: 0;
}

.act-select-wrapper .hotelrow{
    padding: 15px;
    margin-bottom: 15px;
}

.act-select-wrapper .hoteldtlstag{
    padding: 5px;
}

.act-select-wrapper .linkviewhotel{
    font-size: 14px;
}

.package-wrapper{
    padding: 15px;
}
.package-wrapper .btn-border, .package-wrapper .btn-border:hover,.package-wrapper .btn-border:active{
    border-radius: 30px !important;
    background: #fff !important;
    color: #46143d !important;
    border: 1px solid #46143d !important;
    font-size: 12px;
}

.package-wrapper .btn-border-radius{
    border-radius: 30px !important;
    background: #46143d !important;
    color: #fff !important;
    border: 1px solid #46143d;
    font-size: 12px;
}
.package-ttl-1 {
    display: flow-root;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}
.package-heading{
    display: flow-root;
    padding-bottom: 15px;
    border-bottom: 1px solid;
    color: #000;
    font-weight: 600;
}
.package-price{
    display: flow-root;
    padding-bottom: 15px;
    color: #000;
    font-weight: 600;
    font-size: 18px;
}
.pull-right{
    float: right;
}
.pull-left{
    float: left;
}

.package-ul{
    padding: 0px 5px;
    /* border-right: 1px solid #ccc; */
}

.txt-xs{
    font-size: 11px;
    font-weight: 600;
}

.person-btn-grp{
    margin: -6px;
}

.person-btn-grp button{
    color: #000 !important;
}

/* activity booking css */

.actbooking-img-1 img{
    width: 100%;
    height: 88px;
}
.xs-txt-p{
    font-size: 13px;
    font-weight: 600;
    color: #46143d;
    margin-top: 10px;
    margin-bottom: 0px;
}

.info-box {
    border-right: 1px solid #808080;
}

.booking-package-ul {
    padding: 0px 15px;
}

.abstlt {
    display: inline-block;
    padding: 10px 15px;
    background: #0b78bc;
    color: #fff;
    margin-left: -16px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.tlt-input-traveller{
    /* margin-bottom: 5px !important; */
}

.calcBox {
    margin-top: 15px;
    border-radius: 9px;
    padding-top: 10px;
}

.tlt {
    font-size: 18px;
    font-weight: 700;
}

.backtosearch {
    display: flex;
    align-items: center;
}

.fare-tlt {
    font-size: 18px;
    font-weight: 500;
    flex-basis: 67%;
}

button.setbtnback {
    font-size: 13px;
    font-weight: 500;
    padding: 8px !important;
    text-transform: capitalize!important;
}

.selectactivity-container{
    margin-top: 10px;
    margin-bottom: 60px;
}

.reviewbtncontent {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    /* box-shadow: 0 1px 5px -1px rgb(0 0 0 / 40%); */
    text-align: right;
}

.singleimgbox img {
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
    height: 290px ;
}

table.hotelcancellationtbl {
    width: 98%;
    text-align: center;
    margin: 10px auto 0;
}

table tr.firstrowtbl td {
    /* background: #6e9abf; */
    color: #000 !important;
    border: 0;
}

table.hotelcancellationtbl tr td {
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: grey;
}

.tlttravellertype {
    font-size: 13px;
    font-weight: 400;
    color: grey;
    flex-basis: 100%;
}


.fareBox .tlt{
    font-weight: 600 !important;
}
.itinenary-list .MuiAccordionSummary-root {
        min-height: 30px !important;
}
.itinenary-list .MuiPaper-root{
    box-shadow: none;
}
.itinenary-list p{
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
}
.MuiAccordionSummary-content {
    margin: 5px 0 !important;
}

.itinenary-list p li{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
}
.MuiTabs-indicator{
    background-color: #46143d;
}

.MuiButtonBase-root.Mui-selected {
    color: #fff !important;
    border: 1px;
    background: #46143d;
}

.MuiButtonBase-root.Mui-selected:hover {
    color: #fff;
    border: 1px;
    background: #46143d;
}

.hoteltab {
    border-bottom: 1px solid #ddd;
}

.view-images-btn{
    float: right !important;
    padding: 5px 0 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #46143d !important;
    background: #fff !important;
}

.view-images-btn:hover{
    background: #fff;
}

.slideimgbox .slick-prev:before, .slick-next:before {
    font-size: 32px !important;
    opacity: 1 !important;
    color: white !important;
}
.slideimgbox .slick-prev {
    left: -40px !important;
}

.activity-modal button{
    position: absolute;
}

.preview-modal .MuiPaper-root{
    max-width: 100%;
}

.close-modal-btn{
    float: right;
    right: -5%;
    top: -25px;
    z-index: 999999;
    background-color: red !important;
    color: #fff !important;
    font-size: 10px !important;
    padding: 4px !important;
    font-weight: 800;
}

.package-list-ul-1 ul{
    list-style: none;
    padding-left: 15px;
}

.package-list-ul-1 ul li{
    font-size: 13px;
    margin-bottom: 5px;
    display: flex;
}

.package-list-ul-1 ul li::before{
    content: "\2714";
    font-family: FontAwesome;
    display: inline-block;
    background: #4caf50;
    color: #fff;
    font-weight: 300;
    font-size: 8px;
    padding: 0px 3px;
    width: 12px ;
    height: 12px;
    margin-right: 10px;
    margin-top: 3px;
}

.package-list-ul-2 ul{
    list-style: none;
    padding-left: 15px;
}

.package-list-ul-2 ul li{
    font-size: 13px;
    margin-bottom: 5px;
    display: flex;
}
.package-list-ul-2 li p{
    font-weight: 400;
    font-size: 13px;
}

.package-list-ul-2 ul li::before{
    content: "\2716";
    font-family: FontAwesome;
    display: inline-block;
    background: red;
    color: #fff;
    font-weight: 300;
    font-size: 8px;
    padding: 0px 3px;
    margin-right: 10px;
    width: 12px ;
    height: 12px;
    margin-top: 5px;
}



.cancel-hd button{
    position: absolute;
}

.cancel-db h5{
    padding: 0px 15px;
    font-size: 18px;
}

.cancel-policy-db ul li{
    font-size: 13px;
}

.package-highlights-list-ul-1 ul li::before {
    content: "\f058";
    font-family: FontAwesome;
    display: inline-block;
    color: #46143d;
    font-weight: 300;
    font-size: 15px;
    padding: 0px 3px;
    width: 12px;
    height: 12px;
    margin-right: 10px;
}
.package-highlights-list-ul-1 ul li{
    padding-bottom: 3px;
    display: flex;
}

.package-highlights-list-ul-1 ul {
    list-style: none;
    padding: 0;
    font-size: 14px;
}


.image-gallery-thumbnails-container{
    overflow: auto;
}
.image-gallery-slides{
    margin-left: 5%;
}
.image-gallery-slides img{
    max-height: 500px;
    height: 500px;
    width: 100%;
}

.image-gallery-slide .image-gallery-image {
    width: 900px !important;
    object-fit: cover !important;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px;
}

.package-highlights span {
    width: 100%;
    height: 95px;
    overflow: hidden;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-bottom: 0;
}

.details-skltn-mob{
    display: none;
}

.club-vistara-wpr{
    background: #46143d;
    padding: 12px 15px;
    color: #fff;
    margin-top: 10px;
    text-align: center;
}
.cv-inner-wpr{
    display: flex;
    justify-content: center;

}
.club-vistara-wpr img{
    background: #fff;
    width: 120px;
    border: 1px solid #46143d;
}
.cv-logo{
    /* margin-top: -33px; */
    margin-bottom: 10px;
    /* margin: auto; */
}
.club-vistara-wpr p{
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 6px;
}
.cv-inner-left h4{
    font-size: 26px;
}
.cv-inner-left h5{
    color: #dbc59f;
    font-size: 21px;
    padding-top: 3.2px;
}
.cv-inner-right{
    padding: 1px 8px;
    display: flex;
}
.cv-inner-right h6{
    font-size: 24px;
    text-transform: uppercase;
    text-align: left;
    color: #dbc59f;

}

.cv-inner-right span{
    font-size: 24px;
    margin-left: 1px;
    color: #dbc59f;
    text-transform: uppercase;

}
.club-vistara-ad{
    background: #f7f2eb;
    padding: 7px 15px;
    text-align: center;
    /* display: flex; */
    
}
.club-vistara-ad img{
    width: 130px;
    margin-bottom: 10px;
}

.club-vistara-ad a{
    color: #fff !important;
    background: #46143d;
    padding: 3px 10px !important;
    margin: 11px auto !important;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.club-vistara-login p{
    color: #46143d;
    font-weight: 500;
}


.club-vistara-login h6{
    color: #bb9349;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
}



.club-vistara-wpr p span{
    font-size: 15px;
    font-weight: 500;
    color: #46143d;;
}
.club-vistara-box{
    display: flex;
}

.club-vistara-box img{
    width: 150px;
}

.club-vistara-box h1{
    font-size: 20px;
    color: #46143d;
}
@media (max-width: 786px) {
   
    .singleimgbox img {
        height: 150px !important;
    }
    .multiimgbox img {
        height: 80px !important;
    }

    .sideimgboxleft img , .sideimgboxright img{
        height: 45px !important;
    }
    .selectactivity-container{
        padding: 0;
    }
    .faq-section h5{
        font-size: 17px;
    }
    .package-list-ul-1 ul , .package-list-ul-2 ul{
        list-style: none;
        padding: 0px 15px;
    }
    .tlttravellertype {
        font-size: 12px;
    }
    .act-select-wrapper .hotelrow {
        padding: 0 15px ;
    }
    .actfare-ttl-bold {
        font-size: 12px;
    }
    .fare-tlt {
        font-size: 17px;
    }
    .mt-xs-3{
        margin-top: 15px !important;
    }
    .reviewbtncontent {
        display: block !important;
        text-align: left;
    }
    .package-wrapper .btn-border{
        margin-bottom: 10px;
    }
    .travellerdetailsBox .three h1 {
        font-size: 17px !important;
    }

    .details-skltn-des{
        display: none;
    }
    .details-skltn-mob{
        display: block;
    }
}

.faq-search-box{
    /* text-align: right; */
    margin-bottom: 15px;
}
.faq-search-box .inputboxdetails{
    width: 100% !important;
}

.travellerdetailsBox .tlt-pass-categoty .three {
    margin-bottom: 0px;
}
.tlt-pass-categoty .three h1 {
    font-size: 14px !important;
}
.tlt-pass-categoty .three h1:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 30px;
    background-color: #c7a267;
}
.tlt-pass-categoty .three h1:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 95%;
    max-width: 80px;
    background-color: #c7a267;
}
.MuiPaper-root .MuiButtonBase-root.Mui-selected {
    color: #fff !important;
    border: 1px;
    background: #46143d !important;
}
.MuiPaper-root li:first-child {
    background: #fff !important ;
}
.Noresult {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    padding: 25px;
    margin: 25px 0;
    background: aliceblue;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resulttlthotel {
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
    padding: 3px 15px;
    margin-left: -15px;
    width: 100%;
    border-radius: 8px;
}
.tlt-filter-header {
    display: flex;
    align-items: center;
}
.tltrangereset {
    flex-basis: 47%;
    font-size: 14px;
    font-weight: 500;
     color: tomato;
     cursor: pointer;
}
.tltrange-minimize {
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
}
.resulthoteltlt {
    width: 100%;
    padding: 15px;
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 13px;
    box-shadow: 0 0px 3px 1px rgb(0 0 0 / 10%);
}
.items {
    padding: 10px;
    width: 100%;
    height: 165px;
    background: burlywood;
}
.labeltagtxt {
    padding: 3px 19px;
    background: red;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    position: absolute;
    z-index: 3;
    top: 25px;
    right: -13px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.labeltagtxt:after {
    content: "";
    border-top: 0 solid transparent;
    border-left: 0 solid red;
    border-right: 13px solid transparent;
    border-bottom: 3px solid red;
    position: absolute;
    top: -3px;
    right: 0;
}
.labeltagtxt:before {
    content: "";
    border: 14px solid red;
    border-left: 13px solid transparent;
    position: absolute;
    top: 0px;
    left: -14px;
}
.imgBoxgllry {
    width: 100%;
    background: #ccc;
    height: 200px;
    margin-top: 3px;
    border-radius: 8px;
    position: relative;
}
.imgBoxgllry img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px;
    transition: .5s ease;
}
.imgBoxgllry:hover .imghtl {
    opacity: 0.3;
  }
  
.imgBoxgllry:hover button.sethoverbtn {
opacity: 1;
}
.overlayout {
    position: absolute;
    top: 43%;
    left: 28%;
}
.imgBoxgllry-imni {
    display: flex;
    align-items: center;
    margin-top: 4px;
}
button.sethoverbtn {
    font-size: 12px;
    display: none;
}
.imgBoxgllry:hover .imghtl {
    opacity: 0.3;
  }
  .imgBoxgllry:hover  button.sethoverbtn {
   display: block;
  }
  .topscrolldiv {
    position: absolute;
    bottom: 0;
    right: 10%;
    z-index: 12;
}
.itemmini-tagimg {
    width: 48px;
    height: 48px;
    background: #ccc;
    font-size: 13px;
    text-align: center;
    border-radius: 5px;
    margin: 3px;
    cursor: pointer;
}
.itemmini-tagimg img {
    width: 100%;
    height: 100%;
}
.tlt-hotel {
    font-size: 15px;
    font-weight: 600;
    color: #0b78bc;
    margin-bottom: 10px;
}
.addresshotel p {
    font-size: 13px;
    color: #999999;
}
.distancehotel {
    margin-bottom: 5px;
}
.distancehotel svg {
    font-size: 14px;
    color: #999;
}
.distancehotel span {
    font-size: 13px;
    color: #999;
}
.ratingstarhotel {
    font-size: 14px;
    color: #999;
    display: flex;
    align-items: center;
}
img.starico {
    width: 15px;
}
.ratingstarhotel ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 5px;
}
.ratingstarhotel ul li {
    margin-right: 3px;
}
.ratingtlthotel {
    font-size: 12px;
    font-weight: 500;
    color: #999;
    margin-bottom: 5px;
}
span.uponrate {
    padding: 3px 15px;
    background: #28a745;
    border-radius: 15px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    margin-left: 9px;
}
.offernpricedtls {
    text-align: right;
    padding: 6px;
    margin-top: 50px;
}
.rateoff {
    font-size: 19px;
    color: #999999;
    text-decoration: line-through;
    font-weight: 500;
}
.rateopen {
    font-size: 22px;
    font-weight: 600;
    color: #000;
}
.cancellation span {
    font-size: 13px;
    color: #9d805242;
    font-weight: 500;
}
.btnselectroom {
    /* margin-top: 32px; */
    text-align: right;
}
.imgSliderPopup {
    min-width: 700px;
    height: 400px;
    background: #ccc;
    border-radius: 8px;
}

.select-room-btn{
    font-size: 12px !important;
}



  /***gallery csss***/
  .thumbnail-slider-wrap {
    margin-top: 15px;
    height: 85px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 70%;
  }

  .galleyheader {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}
.tltglry {
    flex-basis: 50%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    padding: 12px 0;
    color: #fff;
}
.tabview {
    display: flex;
    width: 100%;
    padding: 6px 0 0 0;
    flex-basis: 80%;
}
.tabitem.active {
    border-bottom: 2px solid #fff;
    color: #fff;
    font-weight: 500;
}
.tabitem {
    font-size: 15px;
    font-weight: 400;
    margin-right: 20px;
    padding-bottom: 5px;
    padding-right: 4px;
    border-bottom: 2px solid transparent;
    color: #f1f1f1;
}
img.slickboximage {
    width: 100%;
    border-radius: 2px;
}
img.thumbnailimage {
    width: 97%;
    border-radius: 10px;
}
.slideimgbox {
    width: 70%;
    margin: 0 auto;
}
.thumbnailbox {
    width: 70%;
    margin: 0 auto;
}

.slider-box{
  
}

.slider-box .close-modal-btn{
    float: right;
    right: 5%;
}
.galleryslider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 15px;
    background: #000000b8;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 12;
    
}
label.slickboxlabel {
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 17px;
    color: #fff;
    font-weight: 400;
    margin: 0;
    letter-spacing: .8px;
}
.btncls button svg {
    font-size: 30px;
    color: #46143d;
}
.btncls {
    flex-basis: 20%;
    text-align: right;
}
/********/
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 34px;
    line-height: 1;
    opacity: 1;
    color: #46143d;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button.activecolor {
    border: 2px solid #0b78bc;
    background: #0b78bc;
    color: #fff;
}
.facilites span {
    font-size: 13px;
    font-weight: 500;
    margin-right: 9px;
    cursor: pointer;
}
.facilites span svg {
    font-size: 13px;
    margin-right: 4px;
}

.ul-activities, .ul-activities-1{
    padding: 0px 12px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
}

.ul-activities-1 li{
    margin-bottom: 10px;
}

.para-activities{
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: justify;
    
}

.tlt-hotel-1{
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0px;
}

.tlt-hotel-2{
    font-size: 15px;
    font-weight: 500;
    color: #46143d;
    margin-bottom: 5px;
}

.text-plan{
    font-size: 16px !important;
    color: #444 !important;
    font-weight: 600 !important;
}

.ratingstaractivity ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 5px 0px;
}

.ratingstaractivity ul li {
    margin-right: 3px;
}

/* activity search bar */

.searchbar-content {
    background: linear-gradient(to right, #9d8052, #bb93497d );
    width: 100%;
    position: fixed;
    top: 75px;
    z-index: 5;
}

.headerWrapper-txt2 {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
}

.searchmodal {
    left: 0;
    margin-top: -23px;
    box-sizing: border-box;
    border-radius: 8px;
    position: absolute;
    border: 1px solid #cfcfcf;
    border-top: none;
    top: 100%;
    width: 100%;
    overflow: hidden;
}
.searchmodal-search-box-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
    justify-content: center;
    padding: 7px 8px;
    background: #fff;
}

.searchmodal-search-box-container .MuiInputBase-formControl {
    width: 100%;
}

.searchmodal-search-box {
    flex: 1 1;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    border: none;
    background: transparent;
    color: #4a4a4a;
}
.searchmodal-content {
    border-top: 1px solid #cfcfcf;
    overflow: auto;
    height: auto;
    background: #fff;
    padding: 15px;
}

.searchmodal-horizontal-content-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.searchmodal-content-title {
    flex: 1 1;
    font-size: 14px;
    color: #4a4a4a;
}


.act-heading-1 {
    font-size: 28px;
    font-weight: 600;
    padding: 10px 0px 15px;
}

.farecontentBox {
    margin-bottom: 20px;
}

.calcBox {
    background: #fff;
    padding: 0px;
    border-radius: 6px;
}

.boxtlt {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.faredetailsBox {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 4px 0px;
}

.fareBox {
    flex-basis: 60%;
}

.tlt {
    font-size: 18px;
    font-weight: 600;
}

.faredetailprice {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    flex-basis: 40%;
}

.tlt-total {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.hotelrow, .tabsrow{
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    overflow: hidden;
}

.headerWrapper-input {
    display: flex;
    flex-direction: row;
}

.headerWrapper-left{
    flex-basis: 100%;
    justify-content: left;
    align-items: center;
    display: flex;

}

.headerWrapper-left-1 {
    flex-basis: 100%;
    justify-content: left;
    align-items: center;
    display: flex;
}

.headerWrapper-left img{
    width: 70%;
}
.headerWrapper-input h1{
    font-size: 17px;
    margin: 0;
    padding: 10px 0;
}

.headerWrapper-input h2{
    font-size: 18px;
    font-weight: 300;
}

.headerWrapper-right{
    flex-basis: 80%;
    text-align: right;
    color: #fff;
}

.headerWrapper-txt1 {
    font-size: 13px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 0px;
}


.date-activity{
    margin: 0px !important;
    padding-bottom: 20px !important;
    width: 100%;
}
.inputboxdetails input{
    font-size: 14px;
    padding: 10px !important;
    height: 17px;
    background-color: #fff;
    border-radius: 3px;
}
.travellerdetails-wpr{
    padding: 15px 45px !important;
}

.travellerdetailsBox .three h1 {
    font-size: 20px;
}

.travellerdetailsBox .three{
    margin-bottom: 10px;
}

.tlttag {
    flex-basis: 100%;
    font-size: 15px;
    font-weight: 500;
    color: #46143d;
    text-align: left;
}
.tlttagreset{
    flex-basis: 50%;
    font-size: 15px;
    font-weight: 500;
    color: red;
    text-align: right;
    cursor: pointer;
}
.listchecktag ul {
    padding: 0;
    list-style-type: none;
    padding: 0 7px;
    margin: 0;
}
label.labelcontrolcheck {
    width: 100%;
    margin: 0;
    /* margin-bottom: 10px; */
}


label.labelcontrolcheck span {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 2px ;
}


.cancellation span{
    display: inline-block;
    height: 50px;
    width: 50px;
    text-align: center;
    /* border: 1px solid #46143d; */
    padding: 13px;
    border-radius: 50%;
    background: #9d805242;
}
.cancellation .disabled{
    background: #9e9e9e26;
}

.cancellation .disabled svg{
    color: #9e9e9e82;
}

.margin-bottomitem {
    margin-bottom: 0px;
    margin-top: 40px;
}
.cancellation svg {
    color: #46143d;
    font-size: 24px;
}
.result-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
}

/* 
.flight-card{
width:700px;
height: 610px;
margin: 100px auto;
border-radius: 50px;
overflow:hidden;
box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}
.flight-card-header{
height: 150px;
width: 100%;
background: linear-gradient(to bottom, #264b76 0%,#002c5f 100%);
padding: 30px 50px;
border-bottom: 7px solid #6DBE47;
position: relative;
}
.flight-logo{
height: 110px;
width:100%;

}

.flight-logo img{
width: 150px;
}
.flight-data{
height:auto;
border-top: 2px solid #3E5177;
padding-top: 1em;
display: grid;
grid-template-columns: 2fr 1fr 1fr;
text-align: left;


}

.flight-card span{
display:block;
}
.flight-card .title{
color: #838EA8;
font-size: 16px;
}
.flight-card .detail{
font-size: 18px;
padding-top: 3px;
color: white;
}



.flight-card-content{
width: 100%;
height: auto;
display: inline-block;
}
.flight-card .flight-row{
width: 100%;
padding: 50px 50px;
display:grid;
grid-template-columns: 1fr 1fr 1fr;
}
.flight-card .plane{
text-align: center;
position: relative;
}
img{
width: 90px;
}
.flight-card .plane:before{
content: '';
width: 135px;
height: 3px;
background: #efefef;
position: absolute;
top: 45px;
left: -75px;
}
.flight-card .plane:after{
content: '';
width: 135px;
height: 3px;
background-color: #efefef;
position:absolute;
top: 45px;
right: -75px;

}
.flight-from{
text-align: left;
float:left;
}
.flight-to{
text-align: right;
float:right;
}

.flight-from,.flight-to span{
display:block;
}
.from-code, .to-code{
font-size: 60px;
color: #002C5F;
font-weight:200;
}
.flight-from,.flight-to  .from-city, .to-city{
font-size: 18px;
color: #002C5F;
font-weight:400;
}

.flight-details-row{
width:100%;
display:grid;
padding: 30px 50px;
grid-template-columns: 1fr 1fr 1fr; 
}
span{
display:block;
color:#002C5F;

}
.title{
color:#6a8597;
font-size:16px;
letter-spacing: 3px;
}
.flight-details-row .detail{
margin-top:.2em;
color:#002C5F;
font-size:18px;
}
.flight-operator{
text-align:left;
float:left;
}
.flight-class{
float:right;
text-align:right;
}
.flight-number{
padding-left:80px;
} */


.itinenary-list .MuiAccordionSummary-root{
    background: #fff;
    width: 100%;
}
.viewdtlshotelcls a{
    color: #46143d !important;
}


.itinenary-list svg.MuiSvgIcon-root {
    font-size: 16px !important;
    color: #46143d;
    background: #fff;
    border: 1px solid #46143d;
    margin-top: 4px;
    border-radius: 50%;
}

.itinenary-list li svg.MuiSvgIcon-root {
    font-size: 13px !important;
    color: #46143d;
    background: #fff;
    margin-top: 4px;
    border-radius: 50%;
    border: none;
}

.hotel-list-simple{
    list-style: none;
}


.hotel-list-simple li{
   font-size: 16px;
   margin-bottom: 5px;
   padding: 0px;
}

.tabboxhotel .cancellation {
    border: 1px solid #fcdee2;
    border-radius: 50px;
}

.tabboxhotel .cancellation p{
    padding: 12px 0;
    font-size: 15px;
    font-weight: 400;
    color: #000;
}
.MuiAccordionSummary-root{
    min-height: 30px !important;
    width: 100%;
    padding: 0 16px;
}
.MuiAccordion-root.Mui-expanded{
    margin-bottom: 0;
    margin-top: 0;
    border-top: 1px solid #ddd;
}
.MuiAccordionSummary-content p h5{
    margin-bottom: 0;
}
.MuiAccordionSummary-root.Mui-expanded{
    min-height: 30px;
}

.MuiAccordion-root{
    box-shadow: none !important;
    /* background: #46143d21; */
    margin-bottom: 0;
    padding: 5px 0px;
}
.MuiAccordionSummary-content {
    margin: 5px 0;
}
.MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0;
    margin-left: 8px;
}
    
.faq-section{
     padding: 0px 20px;
     width: 100%;
}
.faq-section-aacordian{
    /* box-shadow: 1px 1px 2px 1px #ddd; */
}

.faq-section-aacordian p{
    font-size: 14px;
}

.faq-section-aacordian  svg.MuiSvgIcon-root{
    font-size: 10px !important;
}

.faq-section .MuiPaper-elevation1 {
    box-shadow:none !important;
}

.highlights-section li{
    background: #fff;
    /* border: 1px solid #e6576c; */
}

.highlights-section li svg{ 
    font-size: 16px !important;
    background: #46143d;
    color: #fff;
    border-radius: 50%;
    margin-top: 2px;
    padding: 2px;
}

.act-select-wrapper .MuiAccordionDetails-root {
    padding-bottom: 0px;
}
.act-select-wrapper .MuiAccordionDetails-root .MuiTextField-root {
    width: 100px;
}
.accordian-main{
    width: 100%;
}

.accordian-main svg{
    font-size: 20px !important;
    border: 1px solid;
    color: #000;
}

.customized-list .MuiAccordionSummary-content p{
    font-weight: 600;
    font-size: 16px;
}

.customized-list .MuiFormControlLabel-root{
    margin: 1px 15px;
}

.customized-list .MuiFormControlLabel-label{
   font-size: 15px;
   margin-left: 7px;
}

.customized-list .MuiAccordionSummary-root.Mui-expanded {
    /* min-height: 30px;
    background: #46143d14; */
    background: #f2f2f2;
    width: 100%;
}
.customized-list-preview .MuiAccordionSummary-root.Mui-expanded{
    background: #fff !important;
}
.customized-list-preview .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 0;
    margin-left: 8px;
}

.customized-list-preview .MuiAccordionSummary-content p {
    font-weight: 400;
    font-size: 14px;
}

.customized-list-preview .MuiFilledInput-root{
    width: 100px;
}
.finger-icon{
    color: #46143d;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    font-size: 16px !important;
    margin-top: -5px;
    margin-right: 6px;
}

.d-grid{
    display: grid;
}

.sticky-parent{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    overflow: visible;
}

.sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 75px;
}

.selectactivity-container .sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 125px;
}

.MuiAccordionSummary-content p{
    font-weight: 600;
}

.skeleton .card-img{
    box-shadow: 0 0px 3px 1px rgb(0 0 0 / 10%);
    padding: 20px;
}

.no-result-box {
    /* box-shadow: 2px 0px 6px 0px #ddd; */
    padding: 20px 20px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 10px;
}
.no-result-box img{
    width: 30%;
}
.no-result-box  .head-1{
}

.no-result-box  .head-2{
    font-size: 24px;
    font-weight: 700;
    color: #46143d;
    margin-top: -20px;

}

/* .para-activities-div span{
    width: 100%;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-bottom: 0;
} */

.text-show-less {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: calc(2 * 1.5 * 14px);
}

.text-show-more {
    -webkit-line-clamp: unset;
    max-height: none;
}
.para-activities p{
    margin: 0;
}
.para-showmore button{
    background: transparent;
    font-size: 11px;
    color: red;
    padding: 0px 0px !important;
    float: right;
    min-width: -webkit-max-content;
    min-width: max-content;
    display: inline-block;
    border: none;
}

.package-ttl-1 button{
    font-size: 11px;
    color: #46143d;
    font-weight: 600;
    margin: 5px 2px;
    text-transform: capitalize;
}
.MuiSnackbar-root{
    left: auto !important;
    z-index: 99;
}

.custom-modal .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
}

.custom-modal .modal-footer button{
    font-size: 11px;
    padding: 4px 20px;
    font-weight: 600;
    margin-right: 10px;
    border-radius: 20px;
    color: #fff;
}


.preview-modal .modal-dialog {
    max-width: 1200px;
    margin: 1.75rem auto;
}

.preview-modal .modal-footer button{
    font-size: 12px;
    padding: 10px 20px;
    font-weight: 600;
}

.success-dialog h1 {
    color: #008000;
    /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 10px;
  }
.success-dialog  p {
    font-size:20px;
    margin: 0;
  }
.success-dialog i {
  color: #9ABC66;
  font-size: 100px;
}
.success-dialog .card {
  background: white;
  padding: 20px 60px 50px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 50px auto;
  width: 100%;
  text-align: center;
}
/* 
.success-dialog .card-body{
    border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;
} */
  
  .alert{
    background-color: #fff;
    font-size: 25px;
    font-family: sans-serif;
    text-align: center;
    /* height: 100px; */
    padding-top: 50px;
    position: relative;
    /* border: 1px solid #efefda; */
    border-radius: 2%;
    margin: 0;
  }

    .add-flight-btn p{
        color: #e9798a;
        font-size: 12px;
        padding-top: 6px;
        margin-bottom: 5px;
    }
    .add-flight-btn button:hover{
        font-size: 11px;
        padding: 3px 10px;
        font-weight: 600;
        color: #46143d;
        background: #fff !important;
        border: 1px solid #46143d;
    }

    .add-flight-btn button{
        font-size: 11px;
        padding: 3px 10px;
        font-weight: 600;
        background: #46143d !important;
        color: #fff;
        border: 1px solid #46143d;
        border-radius: 20px;
    }
  
  .alert::before{
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 100%;
    /* inset: 20px 0px 0px 100px; */
    font-size: 60px;
    /* line-height: 100px; */
    border: 5px solid gray;
    -webkit-animation-name: reveal;
            animation-name: reveal;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    display: block;
    margin: auto;
  }
  
  .alert>.alert-body{
    opacity:0;
    -webkit-animation-name: reveal-message;
            animation-name: reveal-message;
    -webkit-animation-duration:1s;
            animation-duration:1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-delay:1.5s;
            animation-delay:1.5s;
    -webkit-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
  }
  
  @-webkit-keyframes reveal-message{
    from{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
  
  @keyframes reveal-message{
    from{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
  
  .success{
    color:green;
  }
  
  .success::before{
    content: '✓';
    background-color: #eff;
    box-shadow: 0px 0px 12px 7px rgba(200,255,150,0.8) inset;
    border : 5px solid green;
  }
  
  .error{
    color: red;
  }
  
  .error::before{
    content: '✗';
    background-color: #fef;
    box-shadow: 0px 0px 12px 7px rgba(255,200,150,0.8) inset;
    border : 5px solid red;
  }
  
  @-webkit-keyframes reveal {
    0%{
      border: 5px solid transparent;
      color: transparent;
      box-shadow: 0px 0px 12px 7px rgba(255,250,250,0.8) inset;
      -webkit-transform: rotate(1000deg);
              transform: rotate(1000deg);
    }
    25% {
      border-top:5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(255,250,250,0.8) inset;
      }
    50%{
      border-right: 5px solid gray;
      border-left : 5px solid gray;
      color:transparent;
      box-shadow: 0px 0px 17px 10px rgba(200,200,200,0.8) inset;
    }
    75% {
      border-bottom: 5px solid gray;
      color:gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
      }
    100%{
      border: 5px solid gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
    }
  }
  
  @keyframes reveal {
    0%{
      border: 5px solid transparent;
      color: transparent;
      box-shadow: 0px 0px 12px 7px rgba(255,250,250,0.8) inset;
      -webkit-transform: rotate(1000deg);
              transform: rotate(1000deg);
    }
    25% {
      border-top:5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(255,250,250,0.8) inset;
      }
    50%{
      border-right: 5px solid gray;
      border-left : 5px solid gray;
      color:transparent;
      box-shadow: 0px 0px 17px 10px rgba(200,200,200,0.8) inset;
    }
    75% {
      border-bottom: 5px solid gray;
      color:gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
      }
    100%{
      border: 5px solid gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
    }
  }

.preview-flight-details .acti-flight-list {
    height: auto;
    overflow: hidden;
    padding: 0px;
    border: none;
}
.preview-flight-details .bgflightdetailscard{
    margin: 0;
    padding: 0;
}

.activity-preview .inputFieldBox {
    display: flex;
    margin-bottom: 10px;
    background: #c7a3670d;
    padding: 10px 0px;
}

.activity-preview .sticky-child {
    position: sticky;
    position: -webkit-sticky;
    top: 115px;
}
.activity-preview .tlt-input-traveller {
    font-size: 11.5px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #444;
}

.activity-preview .setinputBox p{
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
}

.evt-date{
    font-size: 12px;
    position: absolute;
    top: -5px;
    right: 5px;
}
.evt-date svg{
    font-size: 15px;
    margin: 2px -1px;
    color: #9d8152;
}
.evt-date p{
    margin-bottom: 0;
    font-size: 10px;
}

.evt-date .para-2{
    margin-bottom: 0px;
    font-size: 14px;
    margin-top: -4px;
    font-weight: 600 !important;
    /* color: #46143e; */
}

#filled-number{
    padding-top: 25px !important;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
}
.rateopen-strike{
    font-size: 13px;
}
.rateopen-strike strike{
    color: #9e9e9e;
}
.rateopen-strike span{
    font-size: 10px;
    color: orangered;
}

@media (min-width: 786px){
    .show-filter-btn{
        display: none;
    }
}
@media (max-width: 786px){
    .flitercolumstick{
        display: none;
        position: relative;
    }
    .monfilter{
        display: block !important;
    }
    .d-block-xs {
        display: block !important;
    }
    .show-filter-btn button{
        padding: 2px 0px;
        font-size: 11px;
        float: right;
        color: #46143d;
        font-weight: 600;
        background: transparent !important;
        border: none;
        outline: none !important;
    }
    .show-filter-btn button:hover{
        padding: 2px 0px;
        font-size: 11px;
        float: right;
        color: #46143d;
        font-weight: 600;
        background: transparent !important;
        outline: none !important;

    }
    .result-right {
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 5px;
        padding: 0 20px;
    }

    .btnselectroom {
        padding: 0px 15px;
    }
    .offernpricedtls {
        text-align: left;
        padding: 15px 15px 5px;
    }
    .headerWrapper-input h1 {
        font-size: 15px;
    }
    .headerWrapper-input h2 {
        font-size: 14px;
    }
    .headerWrapper-txt1 {
        font-size: 10px;
    }
    .tlt-hotel-1 {
        font-size: 17px;
        margin-top: 10px;
    }
    .tlt-hotel-2 {
        font-size: 12px;
    }
    .no-result-box .head-2 {
        font-size: 16px;
        margin-top: -10px;
    }
    .galleryslider{
        margin-top: 0% !important;
    }
    .image-gallery-slides img {
        max-height: 300px;
        height: 300px;
    }
    .slider-box .close-modal-btn {
        float: right;
        right: -5%;
    }
    .cancellation span {
        height: 40px;
        width: 40px;
        padding: 9px;
    }

    .searchbar-content {
        top: 55px !important;
    }

    .evt-date{
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
        padding: 15px 15px 0px;
    }
}

.filter-content-ec {
    width: 100%;
    background: rgba(70,20,61,.03137254901960784);
    padding: 15px;
    border-radius: 8px;
    margin-top: 0;
    box-shadow: 0 2px 3px 0 #ccc;
}
.filter-ec{
    padding-right: 30px;
}
.boxdivition {
    padding: 15px;
    margin: 15px 0;
    background: #fff;
    border-radius: 8px;
    border: none;
}

.tltrange-filter {
    flex-basis: 50%;
    font-size: 16px;
    font-weight: 600;
}

.tltrangereset {
    font-size: 14px;
    font-weight: 500;
    color: tomato;
    width: 55%;
    cursor: pointer;
    text-align: right;
}

.MuiFormControl-root{
    width: 100%;
}

.depositdate-picker input{
    font-size: 13px;
    padding: 8px;
    width: 100%;
}

.report-tlt{
    font-size: 14px;
}

.fc-button-primary{
    background: #46143e !important;
}

.fc-event-main span{
    font-size: 11px;
    padding: 1px 4px !important;
    margin-bottom: 2px;
    cursor: pointer;
}

.fc-daygrid-event-harness{
    margin-top: 2px !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em !important;
}

.label-detector{
    position: fixed;
    top: 25%;
    right: 0;
    z-index: 99;
}
.label-detector ul{
    list-style: none;
    padding: 0px;
}

.label-detector ul li {
    list-style: none;
    padding: 3px 8px;
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 500;
    /* width: max-content;
    margin-right: 15px; */
    border-radius: 2px;
}

.label-detector ul li span{
    height: 5px;
    width: 15px;
    display: inline-block;
    margin-right: 3px;
}
/* cricket label */
.label-cricket {
    background: #c4513f3d;
    /* color: #c4513f; */
}
.label-cricket span{
    border: 4px solid #C4513F;
}

/* Golf label */
.label-golf {
    background: #4F86A43d;
    /* color: #4F86A4; */
}
.label-golf span{
    border: 4px solid #4F86A4;
}

/* football label */
.label-football {
    background: #1F85393d;
    /* color: #1F8539; */
}
.label-football span{
    border: 4px solid #1F8539;
}

/* tennis label */
.label-tennis {
    background: #485C2C3d;
    /* color: #485C2C; */
}
.label-tennis span{
    border: 4px solid #485C2C;
}

/* formula label */
.label-formula {
    background: #837D783d;
    /* color: #837D78; */
}
.label-formula span{
    border: 4px solid #837D78;
}

/* music label */
.label-music {
    background: #02AFD43d;
    /* color: #02AFD4; */
}
.label-music span{
    border: 4px solid #02AFD4;
}

.filter-content-ec {
    display: block;
}
.filter-content-res, .filter-btn-xs{
    display: none !important;
}
@media (max-width: 786px) {
.filter-content-ec {
    display: none;
}
.filter-content-res, .filter-btn-xs {
    display: block !important;
}
.label-detector {
    position: -webkit-sticky;
    position: sticky;
    top: 93px;
    right: 0;
    z-index: 99;
    padding-top: 4px;
    background: #fff;
}   
.label-detector ul {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}
.label-detector ul li{
    margin-right: 10px;
}
button.filter-btn-xs, button.filter-btn-xs:hover, button.filter-btn-xs:active{
    font-size: 13px;
    padding: 3px 10px;
    background-color: #c7a467 !important;
    border: 1px solid;
    margin-top: -20px;
    margin-bottom: 7px;
    float: right;
}
.filter-ec{
    padding-right: 0px;
}
.fc .fc-button{
    padding: 2px 7px !important;
}
}

.fc-view-harness-active{
    height: 800px !important;
}

.footeraddress {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    flex-basis: 27%;
    height: auto !important;
}
.footer {
    background: #000000de;
    color: #fff;
    padding: 20px 30px 20px;

}
.footerimg img {
    width: 60%;
    /* background: #fff; */
}
.footeraddress p, .footeraddress p a {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
    color: #fff !important;
}
.copyright {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    width: 100%;
}
.socialicons {
    width: 100%;
    display: flex;
    border: none !important;
}
.linklist p {
    font-size: 14px;
    padding-right: 35px;
    text-align: justify;
}
.linklistul {
    flex-basis: 25%;
    height: auto !important;
}
.linklistul ul {
    list-style-type: none;
    font-size: 13px;
    margin-bottom: 0;
}
.linklistul ul li {
    line-height: 2;
}
.linklistul ul li a{
    color: #fff !important;
}
.socialicons a svg {
    font-size: 22px;
    color: #000;
}
.footerimg {
    display: block;
}
.footerlogowrp{
    /* margin: auto; */
}
.linklist ul li {
    line-height: 2;
}

.flexboxlink {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 3px solid #fff;
}
.listlinkitemsbox {
    display: flex;
    max-width: 100% !important;
}
.linklist {
    margin-top: 35px;
}

.footer .container-fluid{
    max-width: 1220px;
}
.whatsappchat:hover{
    color: #fff !important;
}
.whatsappchat svg{
    color: #fff !important;
    font-size: 20px !important;
}
.footerPhoneNo svg{ 
    color: #fff;
    font-size: 20px !important;
}
.footer a.nav-link , .whatsappchat{
    font-weight: 400!important;
    background: transparent;
    font-size: 13px;
    color: #fff !important;
}



.socialicons a{
    font-weight: 400!important;
    background: #fff;
    font-size: 13px;
    color: #fff !important;
    display: inline-flex;
    margin-right: 10px !important;
    padding: 10px !important;
    border-radius: 50%;
    margin-top: 15px !important;
}
.navbar-items {
    width: 60%;
}
.navbar{
    padding: 0px 0px !important;
}
nav ul.items-navbar {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 12px;
}

ul.items-navbar li a {
    font-size: 15px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

ul.items-navbar li {
    margin: 0 7px;
    padding: 10px;
    text-align: center;
}

.user_section_notification {
    width: 100%;
    text-align: end;
    margin-top: 25px;
    position: relative;
}

.flight-ico {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.itw-logo {
    width: 175px;
    background: #fff;
    margin-right: 5px;
}

.NavbarHeader{
    margin: 0 5px;
}

.logo {
    text-align: center;
    padding-top: 0px;
    display: flex;
    flex-basis: 10%;
    width: 25%;
}

.headerBox {
    background-color: #fff!important;
    border-bottom: 3px solid #fc9e15;
}

ul.items-navbar li a span svg {
    font-size: 20px;
}

svg.MuiSvgIcon-root {
    font-size: 20px;
}

button.btn-drop {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

button.btn-notify {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

.top-header-dropdown {
 
}
.balance-nav{
    width: 100%;
    display: flex;
}
.balanced-view {
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 500;
    width: auto;
    color: #fc9e15;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #ccc;
}

.dropdown-profile {
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.notify-cnt {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
}

.notify_show {
    display: block;
}

.notify_hide {
    display: none;
}

.notify-items {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 7px 10px;
    cursor: pointer;
}

.notify-items:hover {
    background: beige;
}

.notifycontenthover {
    position: absolute;
    width: 300px;
    right: 0;
    z-index: 9;
}

.higlighttxt {
    font-size: 13px;
    padding: 0 5px;
    white-space: nowrap;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Blurtxt {
    font-size: 11px;
    padding: 0 5px;
}

.notify-viewmore {
    padding: 15px;
    text-align: center;
}

.viewmore {
    font-size: 15px;
    color: #1675b8;
}

.blc-list {
    background: #fff;
    padding: 5px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 30%);
    right: 0;
    top: 34px;
    border-radius: 8px;
}

.itemblc {
    display: flex;
    align-items: center;
    padding: 7px 10px;
}

.blnc-items-l {
    flex-basis: 50%;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-align: start;
}

.blnc-items-r {
    flex-basis: 50%;
    text-align: end;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.blnc-items-r.balance-price {
    color: red;
}

.balance_show {
    display: block;
}

.balance_hide {
    display: none;
}

.profile_view {
    display: flex;
}

.tltprofile {
    flex-basis: 100%;
}

button.profile {
    font-size: 12px;
    padding: 4px 10px;
    text-align: left;
    width: -webkit-max-content;
    width: max-content;
    
}

.viewdropdown {
    width: 270px!important;
    right: 1!important;
    top: 7px!important;
    left: -28px!important;
    z-index: 99;
}

.profile-bg {
    background: #46143d;
    padding: 10px;
}

.tlt-img-ico {
    width: 70px;
    height: 70px;
    border: 6px solid #9fcc3a;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}

.tlt-img-ico img {
    width: 100%;
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
    height: 100%;
}

.tlt-name {
    font-size: 17px;
    margin-top: 5px;
    color: #fff;
    font-weight: 500;
    text-align: right;
}

.activedate {
    font-size: 13px;
    color: #fff;
    padding-bottom: 10px;
}

.btnadmin {
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.link-contact {
    display: flex;
    text-align: center;
}

.contactnumber {
    text-align: right;
    font-size: 13px;
}

.contactmail {
    text-align: right;
    font-size: 13px;
    text-transform: lowercase;
}

button.btnadminlink {
    font-size: 10px!important;
    padding: 4px 8px!important;
}

.link-contact {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 4px 10px;
    background: azure;
}
.profilenChangespassword a {
    display: block;
    text-align: left;
}
ul.setlogout {
    padding: 0;
}
button.btnprofileset {
    padding: 5px 20px;
    font-size: 14px;
    text-transform: capitalize;
    width: 100%;
}
button.btnprofileset span {
    justify-content: flex-start;
}
button.changebtn {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.changebtn span {
    justify-content: flex-start;
}
button.linklogout {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.linklogout span {
    justify-content: flex-start;
}
ul a li.linklogout {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    text-align: center!important;
    width: 86px;
    margin: 0 auto;
}

/***updated header css**/

.header {
    background-color: #46143d;
    width: 100%;
    margin: 0;
    padding: 1px 30px;
    box-shadow: inset 0px -1px 0px #f3f3f4;
}
.Main-content-header {
    z-index: 999;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.headercol {
    padding: 0!important;
}
.navbar-light .navbar-brand{
    flex-basis: 35%;
}

.navbar-light .navbar-nav .nav-link {
    color: #000!important;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #1675b8!important;
    font-weight: 500;
}

a.navbaritems.nav-link {
    padding: 0 12px!important;
}

.dropdown-toggle::after {
    display: none!important;
}

.dropdownitem.nav-item.dropdown a {
    margin-left: 5px;
    padding: 0 5px!important;
}
.dropdownitem.nav-item.dropdown a li {
    font-size: 15px;
}

a.recharge{
    margin-right: 10px !important;
    padding: 5px !important;
    font-size: 14px;
    font-weight: 500;
}

/******updated header css******/
.rc-menu{
    z-index: 999;
}
button.rc-menu-button.upadtedmenuitems {
    outline: none;
    border: none;
    background: transparent;
    font-weight: 500;
    margin: 0 6px;
    font-size: 18px;
    display: flex;
    color: #000;
}

button.rc-menu-button.upadtedmenuitems svg.MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
    margin-top: 2px;
}

a.nav-link {
    font-weight: 400!important; 
    background: transparent;
    font-size: 13px;
}

.tlt-id{
    font-size: 13px;
    color: #fff;
    text-align: right;
}
.btnprofileset{
    color: #fff;
    text-align: right;
    margin-bottom: 0px;
    font-size: 11px;
    border: 1px solid;
    width: -webkit-max-content;
    width: max-content;
    float: right;
    padding: 2px 5px;
    margin-top: 5px;
}

.badge-custom-flight .MuiBadge-badge {
    font-size: 10px;
    height: 15px;
    background: #1675b8;
}

.topheader-left{
    flex-basis: 40%;
    justify-content: flex-start;
}
.topheader-center{
    flex-basis: 20%;
    justify-content: center;
    text-align: center;
}
.topheader-right{
    flex-basis: 40%;
    justify-content: flex-end;
}
.header .socialicons{
    text-align: center;
    justify-content: center;
    align-items: center !important;
    margin-right: 20px;
}
.header .socialicons a {
    font-weight: 400!important;
    background: #9d8052;
    font-size: 13px;
    color:  #46143d!important;
    display: inline-flex;
    margin-right: 10px !important;
    height: 40px;
    width: 40px;
    padding: 11px !important;
    border-radius: 50%;
    margin-top: 0 !important;
   
}

.header .socialicons a svg{
    font-size: 18px;
    color:  #fff!important;   
}

.auth-btns{
    display: flex;
}

.auth-btns .login-btn{
    width: -webkit-max-content;
    width: max-content;
    background: #9d8052 !important;
    border: 1px solid #46143d;
    color: #fff !important;
    font-weight: 400 !important;
    border-radius: 30px !important;
    padding: 6px 30px !important;
    font-size: 14px;
}

.auth-btns .signup-btn{
    width: -webkit-max-content;
    width: max-content;
    background: #46143d !important;
    border: 1px solid #46143d;
    color: #fff !important;
    font-weight: 400 !important;
    border-radius: 30px !important;
    padding: 6px 20px !important;
    font-size: 14px;

}

.dailogbox-container .MuiDialog-paper{
    max-width: 900px;
    overflow: hidden;
  }
  .login-dialog .MuiDialog-paper{
    max-width: 750px;
    overflow: hidden;
  }
  
  .dailogbox {
    display: flex;
    max-width: 100%;
    height: 600px;
}

.login-dialog .modal-dialog{
    max-width: 800px;
}

.dailogbox-container .modal-dialog{
    max-width: 800px;
}
  .inputsect_login {
    width: 50%;
    padding: 22px;
    position: relative;
    text-align: center;
  }
  .inputsect_signup {
    width: 100%;
    padding: 22px;
    position: relative;
    text-align: center;
  }
  .slidersect {
    width: 50%;
    height: 480px;
    background-color: #0977bc;
  }
    
    .titlefrm {
      font-size: 20px;
  }
  .btnpostion {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 15px;
  }
  .Btnlogin {
    width: 100%;
  }
  .loginfrg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  .loginfrg span {
    font-size: 14px;
    color: #000;
    font-weight: 400;
  }
  .textBoxlogin {
    margin-top: 6%;
  }
  .textBoxsign{
    margin-top: 5%;
  }
  img.logoimg {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  .signup-modal img.logoimg {
    width: 100%;
    height: 650px;
    object-fit: cover;
  }
  .namewithtilebox {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }
  .titleuser {
    flex-basis: 18%;
  }
  .titlefirstname {
    flex-basis: 38%;
  }
  .titlelastname {
    flex-basis: 38%;
  }
  .titlecode {
    flex-basis: 20%;
  }
  .titlenumber {
    flex-basis: 77%;
  }
  .ttext {
    font-size: 13px;
    margin-top: 10px;
    color: #808080;
  }
  .btnpostion_signup {
    width: 100%;
    margin-top: 10px;
  }
  .img_with {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .logingoogle {
    padding: 10px 0;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .logingoogle iframe{
    border: none;
   margin: auto !important;
  }

   iframe #container{
    padding: 0px !important;
    width: 95% !important;
    margin-left: 16px;
  }
  
  .titleuser .MuiFormControl-root, .titlecode .MuiFormControl-root {
    width: 100%;
    margin-top: 8px
  }
  
  .titleuser .MuiSelect-select , .titlecode .MuiSelect-select{
    padding: 9px 8px !important;
  }
  
  .inputsect_signup label, .inputsect_login label{
    display: block;
    text-align: left;
    line-height: 10px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
  }
.texttitle{
    position: relative;
    bottom: 0px !important;
    font-size: 12px;
    padding-bottom: 0px;
  }

  .loginlogo {
    width: 70px;
}

.emailfield input{
    padding: 10px;
    font-size: 14px;
}

.forgot-password span{
    font-size: 15px;
    color: #f44336 !important;
    margin-bottom: 5px !important;
    display: block;
    cursor: pointer;
}

.signup-text{
    margin-top: 10px;
}

.signup-text a{
    font-size: 13px;
}
.signup-text button{
    background: #fff;
    color: #46143d;
    font-size: 13px;
}
.captchaSection {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
.captchaImg {
    height: 20px;
    width: 20px;
    margin : 5px;
}

.popupHeader h6 {
    font-size: 17px;
    font-weight: 600;
}

h3.subtlt-popup {
    font-size: 15px;
    font-weight: 500;
    padding: 0 9px;
    margin: 15px 0 0;
}
.inputforgot input {
    width: 100%;
    padding: 12px;
    font-size: 13px;
}
.inputforgot {
    width: 95%;
    margin: 8px 0 8px 10px!important;
}

.smalltxt {
    padding: 8px 12px;
    font-size: 14px!important;
    color: #a5a5a5;
}

.header.sticky-parent{
    overflow: visible;
}

.header.sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 0px !important;
    z-index: 999;
}
.box-header-clickaway{
    width: 100%;
}

.logo-side{
    display: inline-block;
    position: absolute;
    top: 16px;
}

.logo-side p{
    margin-bottom: -2px;
    font-size: 8px;
    color: #fff;
    margin-left: 15px;
}

.logo-side img{
    width: 100px;
    border-left: 1px solid #ffffff82;
    padding-left: 14px;
    margin-top: 4px;
}

@media (max-width: 786px) {
    .header .socialicons{
        justify-content:flex-start !important;
    }
    .itw-logo {
        width:120px !important;
    }
    .logo-side img {
        width: 80px;
    }
    .logo-side p {
        margin-bottom: -6px;
    }
    .navbar-light .navbar-brand {
        flex-basis: 50%;
    }
    .navbar-toggler-icon {
        width: 20px !important;
        height: 20px !important;
    }

    .Main-content-header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .navbar-collapse.collapse.show{
        position: absolute;
        background: #fff;
        width: 100%;
        top: 75px;
        right: 0px;
        box-shadow: 2px 4px 7px 2px #ccc;
        padding: 20px;
        text-align: center;
        z-index: 999;
    }

    .navbar-collapse.show{
        display: flex !important;
        align-items: flex-start !important;
        flex-flow: wrap-reverse;
    }
    .navbar-menu{
        margin-right: 15%;
        margin-left: 0px !important;
        text-align: left;
        align-items: baseline;
    }
    .tlt-name {
        font-size: 12px;
    }
    .top-header-dropdown {
        text-align: center;
        z-index: 9;
    }
    .itemblc {
        display: flex;
        align-items: center;
        padding: 4px 0px;
    }
    .navbar-expand{
        justify-content: flex-start !important;
        padding: 20px !important;
    }
    .navbar-expand .navbar-nav {
        flex-direction: column !important;
    }

    .header-topup {
        width: -webkit-max-content;
        width: max-content;
    }
    .box-header-clickaway{
        width: 30%;
        text-align: right;
    }
    .box-header-clickaway button{
        background: #c7a367;
        padding: 3px !important;
        border-radius: 2px !important;
    }
    .navbar {
        padding: 5px 0px !important;
    }
}
